import React, { useEffect, useRef, useState } from "react"
import AccordionMenu from "../ring-navigation/accordion-menu"
import { graphql, Link, useStaticQuery } from "gatsby"
import { navigate } from "gatsby"
import { CSSTransition } from "react-transition-group"

const parseSections = (storyNavigationGlobals) => {
  let sections = [];
  sections.push({
    items: storyNavigationGlobals.storyOneItems,
    title: storyNavigationGlobals.storyOne,
    description: storyNavigationGlobals.storyOneDescription,
    backgroundImage: storyNavigationGlobals.storyOneBackground.localFile.publicURL,
    backgroundImageColor: storyNavigationGlobals.storyOneBackgroundColor.localFile.publicURL,
    navBackground: `rgba(65, 210, 175, 0.4)`,
    pieVariant: 'halves',
  });
  sections.push({
    items: storyNavigationGlobals.storyThreeItems,
    title: storyNavigationGlobals.storyThree,
    description: storyNavigationGlobals.storyThreeDescription,
    backgroundImage: storyNavigationGlobals.storyThreeBackground.localFile.publicURL,
    backgroundImageColor: storyNavigationGlobals.storyThreeBackgroundColor.localFile.publicURL,
    navBackground: `rgba(92, 128, 238, 0.4)`,
    pieVariant: 'sixths'
  });
  sections.push({
    items: storyNavigationGlobals.storyTwoItems,
    title: storyNavigationGlobals.storyTwo,
    description: storyNavigationGlobals.storyTwoDescription,
    backgroundImage: storyNavigationGlobals.storyTwoBackground.localFile.publicURL,
    backgroundImageColor: storyNavigationGlobals.storyTwoBackgroundColor.localFile.publicURL,

    navBackground: `rgba(252, 76, 59, 0.4)`,
    pieVariant: 'sevenths'
  });

  return sections;
}

const getStoryMap = (sections) => {
  const storyMap = new Map();
  sections.forEach((section, i) => {
    section.items.forEach(item => {
      storyMap.set(item.story[0].uri, {
        section: i,
        story: item.story[0]
      });
    });
  });

  return storyMap;
}

const SecondaryNavigation = ({ location }) => {

  const {wp: {storyNavigation: {storyNavigationGlobals}}} = useStaticQuery(graphql`
  {
    wp {
      storyNavigation {
        storyNavigationGlobals {
          ringNavTitle
          ringNavDescription
          ringNavCenterText
          storyOne
          storyOneDescription
          storyOneBackground {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                gatsbyImageData
              }
            }
          }
          storyOneBackgroundColor {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                gatsbyImageData
              }
            }
          }
          storyOneItems {
            navItem
            story {
              ... on WpPost {
                id
                uri
                title
                categories {
                  nodes {
                    slug
                  }
                }
                storytellingPage {
                  fieldGroupName
                  navContent {
                    quote
                    attribution
                    description
                    centerCircleText
                    audioFile {
                      caption
                      localFile {
                        publicURL
                        relativePath
                        url
                      }
                    }
                    backgroundImage {
                      localFile {
                        relativePath
                        publicURL
                      }
                    }
                  }
                }
              }
            }
          }
          storyTwo
          storyTwoDescription
          storyTwoBackground {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                gatsbyImageData
              }
            }
          }
          storyTwoBackgroundColor {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                gatsbyImageData
              }
            }
          }
          storyTwoItems {
            navItem
            story {
              ... on WpPost {
                id
                uri
                title
                categories {
                  nodes {
                    slug
                  }
                }
                storytellingPage {
                  navContent {
                    quote
                    attribution
                    description
                    centerCircleText
                    audioFile {
                      caption
                      localFile {
                        publicURL
                        relativePath
                        url
                      }
                    }
                    backgroundImage {
                      localFile {
                        relativePath
                        publicURL
                      }
                    }
                  }
                }
              }
            }
          }
          storyThree
          storyThreeDescription
          storyThreeBackground {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                gatsbyImageData
              }
            }
          }
          storyThreeBackgroundColor {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                gatsbyImageData
              }
            }
          }
          storyThreeItems {
            navItem
            story {
              ... on WpPost {
                id
                uri
                title
                categories {
                  nodes {
                    slug
                  }
                }
                storytellingPage {
                  navContent {
                    quote
                    attribution
                    description
                    centerCircleText
                    audioFile {
                      caption
                      localFile {
                        publicURL
                        relativePath
                        url
                      }
                    }
                    backgroundImage {
                      localFile {
                        relativePath
                        publicURL
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

  const sections = parseSections(storyNavigationGlobals);
  const storyMap = getStoryMap(sections);
  const navContainerRef = useRef();

  const [activeStory, setActiveStory] = useState(null);
  const [activeSection, setActiveSection] = useState(-1);
  const [activeNav, setActiveNav] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const mobileNavReference = useRef();
  const [refExpText, setRefExpText] = useState('Explore the refugee experience');

  const handleScroll = (e) => {
    if (window.scrollY > (window.innerHeight * 0.75)) {
      setActiveNav(true);
    }
    if (window.scrollY < (window.innerHeight * 0.65)) {
      setActiveNav(false);
    }
  }

  const handleMobileRefExpDrawer = (target) => {
    if (window.innerWidth < 1300) {
      if (target === mobileNavReference.current) {
        setMobileNavOpen(!mobileNavOpen);
      }
    }
  }

  const handleWindowClick = target => {
    if (navContainerRef.current && !navContainerRef.current.contains(target)) {
      setMobileNavOpen(false);
    }
  }

  useEffect(() => {
    const active = storyMap.get(location.pathname);
    if(active) {
      setActiveStory(active.story);
      setActiveSection(active.section);
    } else {
      setActiveStory(null);
      setActiveSection(-1);
    }

    if (window.innerWidth > 1300) {
      setRefExpText('the refugee experience');
    }

    window.addEventListener('scroll', (e) => handleScroll(document.innerHeight));
    window.addEventListener('click', (e) => handleWindowClick(e.target));
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('click', handleWindowClick);
    }

  }, [location]);

  const handleSectionClicked = (index, isActive, sectionData) => {
    setActiveSection(index);
  }

  const handleStoryClicked = (story) => {
    navigate(story.uri);
  }

  return (
    <CSSTransition in={activeNav} timeout={1000} classNames="nav-transition" unmountOnExit>
      <div
        className={`secondary-navigation active ${mobileNavOpen ? 'mobile-nav-open' : ''}`}
        ref={navContainerRef}>
        {/* <div
          // onMouseEnter={() => handleDeviceMouseEnter()}
          // className="device-container"> */}
          <div
            // onMouseLeave={() => handleDeviceMouseExit()}
            className="accordion-container">
            <p className="eyebrow eyebrow--small" tabIndex={0} onClick={(e) => handleMobileRefExpDrawer(e.target)} ref={mobileNavReference}>{refExpText}</p>
            <AccordionMenu
              onSectionClick={(index, isActive, sectionData) => handleSectionClicked(index, isActive, sectionData)}
              onStoryClick={(story) => handleStoryClicked(story)}
              activeSection={activeSection}
              activeStory={activeStory}
              // showBackLink={true}
              sections={sections} />
          </div>
        {/* </div> */}
      </div>
    </CSSTransition>
  )
}

export default SecondaryNavigation;