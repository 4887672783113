import React from 'react';
import RefugeeExperience from "../components/refugee-experience"
import PageTransition from "../helpers/page-transition"

const RingRefactor = ({location}) => {
  return (
    <PageTransition location={location}>
      <RefugeeExperience/>
    </PageTransition>
  )
}

export default RingRefactor;