import React, {useState, useRef, useEffect} from 'react' 

const Audio = ({color, linePosition, src, caption}) => {

  const [playState, setPlayState] = useState("init");
  const audioButtonElement = useRef(null);
  const audioElement = useRef(null);
  const id = Math.floor(Math.random() * 10000);



  function handleClick() {
    isNewAudio();

    if (playState === "init") {
      audioElement.current.play();
      setPlayState("playing");
    } else if (playState === "playing") {
      audioElement.current.pause();
      setPlayState("paused") 
    } else {
      audioElement.current.play();
      setPlayState("playing");
    }
  }

  function isNewAudio() {
    const allAudioButtonElements = document.querySelectorAll("button.audio");
    allAudioButtonElements.forEach( (button) => {
      const audio = button.children[0];
      if (button !== audioButtonElement.current) {
        audio.pause();
        const fulltime = audio.duration;
        audio.currentTime = fulltime + 1;  // triggers 'ended' and resets player to beginning
        button.classList.remove('playing', 'paused');
      }
    })
  }

  function resetAudio() {
    setPlayState('init');
    audioElement.current.currentTime = 0;
  }

  useEffect( () => {
    audioElement.current.addEventListener('stop',  () => resetAudio())
    audioElement.current.addEventListener('ended',  () => resetAudio())
  })

  return (
    <button
      className={`audio ${color ? color : ''} ${linePosition} ${playState}`}
      onClick={handleClick}
      ref={audioButtonElement}
      id={id} >
      <audio
        ref={audioElement}
        preload="auto"
        src={src} >
          <track src={caption ? caption : ''} kind="captions" srcLang="en" label="english_captions" />
      </audio>
      <span className="audio--icon"></span>
      <span className="audio--play"></span>
      <span className="audio--pause"></span>
      play audio
    </button>
  )
}

export default Audio;