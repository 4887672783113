import React from "react"

const Documents = ({headline, copy, documents}) => {

  return (
      <div className="documents-wrapper">
        <h3 className="h5">{headline}</h3>
        { copy && 
          <p className="p2 sans">{copy}</p>
        }

        <div className="documents">
          { documents.map( (document, idx) => {
            if (document.subhead) {
              return (
                <>
                  <p className="eyebrow eyebrow--small">{document.subhead}<span></span></p>
                  <div className="document" key={idx}>

                    {document.document.map( document => {
                      switch (document.__typename) {
                        case "WpPage_Tertiarypages_PageContent_Resources_documentsBlock_documents_Document_File" :
                          const fileType = document.download.mimeType === "application/zip" ? '.ZIP' : 'PDF'
                          return (
                            <a href={document.download.localFile.publicURL} key={idx} target="_blank" rel="noreferrer" download>
                              <span><img src="/file-icon.svg" alt="file icon" /></span>
                              <span>{document.fileName} ({fileType})</span>
                            </a>
                          )
                          case "WpPage_Tertiarypages_PageContent_Resources_documentsBlock_documents_Document_Link" :
                            const setRel = document.link.target === '_blank' ? "noreferrer" : ''
                            return (
                              <a href={document.link.url} target={document.link.target} rel={setRel} key={idx}>
                                <span><img src="/link-icon.svg" alt="link icon" /></span>
                                <span>{document.link.title}</span>
                              </a>
                            )
                          default :
                            return <p className="p3">Sorry, we could not match your module</p>;
                      }})}
                  </div>
                </>
              )
            } else {
              return (
                <div className="document" key={idx}>
                  {document.document.map( document => {
                    switch (document.__typename) {
                      case "WpPage_Tertiarypages_PageContent_Resources_documentsBlock_documents_Document_File" :
                        const fileType = document.download.mimeType === "application/zip" ? '.ZIP' : 'PDF'
                        return (
                          <a href={document.download.localFile.publicURL} key={idx} target="_blank" rel="noreferrer" download>
                            <span><img src="/file-icon.svg" alt="file icon" /></span>
                            <span>{document.fileName} ({fileType})</span>
                          </a>
                        )
                      case "WpPage_Tertiarypages_PageContent_Resources_documentsBlock_documents_Document_Link" :
                          const setRel = document.link.target === '_blank' ? "noreferrer" : ''
                        return (
                          <a href={document.link.url} target={document.link.target} rel={setRel} key={idx}>
                            <span><img src="/link-icon.svg" alt="link icon" /></span>
                            <span>{document.link.title}</span>
                          </a>
                        )
                      default :
                        return <p className="p3">Sorry, we could not match your module</p>;
                    }})}
                    </div>
              )
            }})}
        </div>
      </div>
  )
}

export default Documents;
