import React, { useEffect, useState } from "react"
import AccordionSection from "./accordion-section";
import { Link } from "gatsby"

const AccordionMenu = ({onStoryClick, sections, activeSection, onSectionClick, showBackLink = false, activeStory}) => {

  const [activeIndex, setActiveIndex] = useState(activeSection);

  useEffect(() => {
    setActiveIndex(activeSection);
  }, [activeSection]);

  const handleSectionClicked = (index, isActive) => {
    if(onSectionClick) {
      onSectionClick(index, isActive, sections[index]);
    }
  };

  const handleItemSelected = (story) => {
    if(onStoryClick) {
      onStoryClick(story)
    }
  };

  return (
    <div className="accordions">
      {sections.map((section, index) => {
        return (
          <AccordionSection
            key={index}
            title={section.title}
            items={section.items}
            active={index === activeIndex}
            // underlineActive={showBackLink}
            activeStory={activeStory}
            onItemSelect={(story) => handleItemSelected(story)}
            onClick={(isActive) => handleSectionClicked(index, isActive)} />
        )
      })}
      {/* {showBackLink ? <Link to="/#refugee-experience" className="p4 accordion__back-link">Back to the Refugee Experience</Link> : ''} */}
    </div>
  )
}

export default AccordionMenu;