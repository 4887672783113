import React from "react"

const Wedges = () => {
  return (
    <div>
      {/* Hidden SVG wedges used by clip-path in CSS */}
      {/* Thirds */}
      <svg width="0" height="0" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="third-wedge" clipPathUnits="objectBoundingBox">
            <path d="M0.5 1C0.412241 1 0.326027 0.976902 0.250023 0.933026C0.174019 0.88915 0.110901 0.826043 0.0670137 0.750046C0.023126 0.674049 1.39258e-05 0.587839 6.28928e-09 0.500079C-1.39132e-05 0.41232 0.0230708 0.326103 0.0669344 0.250092L0.283467 0.375046C0.261535 0.413051 0.249993 0.45616 0.25 0.50004C0.250007 0.543919 0.261563 0.587024 0.283507 0.625023C0.305451 0.663021 0.337009 0.694575 0.375011 0.716513C0.413014 0.738451 0.45612 0.75 0.5 0.75L0.5 1Z" fill="#1C1C1C" />
          </clipPath>
        </defs>
      </svg>

      {/* Half */}
      <svg width="0" height="0"  fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="half-wedge" clipPathUnits="objectBoundingBox">
            <path d="M0 0.5C1.1593e-08 0.367392 0.0526784 0.240215 0.146447 0.146447C0.240215 0.0526784 0.367392 1.42268e-08 0.5 0C0.632608 -1.42268e-08 0.759785 0.0526784 0.853553 0.146447C0.947322 0.240215 1 0.367392 1 0.5L0.75 0.5C0.75 0.433696 0.723661 0.370107 0.676777 0.323223C0.629893 0.276339 0.566304 0.25 0.5 0.25C0.433696 0.25 0.370107 0.276339 0.323223 0.323223C0.276339 0.370107 0.25 0.433696 0.25 0.5H0Z" fill="#1C1C1C"/>
          </clipPath>
        </defs>
      </svg>

      {/* Sixth */}
      <svg width="0" height="0" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="sixth-wedge" clipPathUnits="objectBoundingBox">
            <path d="M0.5 0C0.587733 1.0462e-09 0.673921 0.0230845 0.749909 0.066935C0.825897 0.110785 0.88901 0.173858 0.932908 0.249819L0.716454 0.374909C0.694505 0.336929 0.662949 0.305393 0.624955 0.283467C0.586961 0.261542 0.543866 0.25 0.5 0.25V0Z" fill="#1C1C1C"/>
          </clipPath>
        </defs>
      </svg>

      {/* Seventh */}
      <svg width="0" height="0" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="seventh-wedge" clipPathUnits="objectBoundingBox">
            <path d="M0.5 0C0.575112 8.95696e-10 0.649258 0.0169226 0.716932 0.0495107C0.784606 0.0820988 0.844068 0.129515 0.890902 0.188238L0.695451 0.344119C0.672034 0.314758 0.642303 0.291049 0.608466 0.274755C0.574629 0.258461 0.537556 0.25 0.5 0.25V0Z" fill="#1C1C1C"/>
          </clipPath>
        </defs>
      </svg>

      {/* Skinny ring */}
      <svg width="0" height="0" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="skinny-ring" clipPathUnits="objectBoundingBox">
            <path d="M1 0.5C1 0.776142 0.776142 1 0.5 1C0.223858 1 0 0.776142 0 0.5C0 0.223858 0.223858 0 0.5 0C0.776142 0 1 0.223858 1 0.5ZM0.0690608 0.5C0.0690608 0.738001 0.261999 0.930939 0.5 0.930939C0.738001 0.930939 0.930939 0.738001 0.930939 0.5C0.930939 0.261999 0.738001 0.0690608 0.5 0.0690608C0.261999 0.0690608 0.0690608 0.261999 0.0690608 0.5Z" fill="#1C1C1C"/>
          </clipPath>
        </defs>
      </svg>

      {/*This is a blur effect as fallback for Firefox*/}
      <svg xmlns="https://www.w3.org/2000/svg" version="1.1" height="0">
        <filter id="blur-filter" width="110%" height="100%">
          <feGaussianBlur stdDeviation="2" result="blur" />
        </filter>
      </svg>
    </div>
  )
}

export default Wedges;