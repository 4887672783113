import React, {useEffect} from 'react'

const ScrollAnimation = ({zIndex=0, children }) => {
  // We set the z-index in reverse order of natural stacking order
  //  to allow the tooltip to stack on top of modules that come after it.

  const fadeInSlideUp = () => {
    document.querySelectorAll('.el-to-animate').forEach( el => {
      // special case for Basic Quote with background module
      if (el.offsetParent.classList.contains('basic-quote--background')) {
        let offsetTop = 0
        let ele = el;
        while(ele) {
          offsetTop += ele.offsetTop
          ele = ele.offsetParent
        }
        if ( offsetTop < (window.scrollY + (window.innerHeight - 100)) ) {
          el.classList.add('visible');
        } else {
          el.classList.remove('visible')
        }
      }

      else if ( el.offsetTop < (window.scrollY + (window.innerHeight - 100)) ) {
        el.classList.add('visible');
      } else {
        el.classList.remove('visible')
      }
    })
  }

  useEffect( () => {
    fadeInSlideUp();
    window.addEventListener('scroll', fadeInSlideUp)
  }, [])

  return ( 
    <div className="el-to-animate" style={{'zIndex': `${zIndex}`}}>
      {children}
    </div>
  )

}

export default ScrollAnimation;