import React, { useEffect, useRef, useState } from "react"
import { CSSTransition, SwitchTransition } from "react-transition-group"

const SmoothText = ({name, text, className = "", showCta = true}) => {

  const foregroundBuffer = useRef();
  const outerRef = useRef();
  const isTransitioning = useRef(false);

  const crossfadeText = (frontBuffer, text) => {

    isTransitioning.current = true;

    const afterFadein = () => {
      frontBuffer.current.removeEventListener('transitionend', afterFadein);
      isTransitioning.current = false;
    }

    const afterFadeout = () => {
      frontBuffer.current.removeEventListener('transitionend', afterFadeout);
      frontBuffer.current.innerHTML = showCta ?
        `<span>${text}</span><span class="story-cta">Click a category to learn more</span>` : `<span>${text}</span>`;
      frontBuffer.current.addEventListener('transitionend', afterFadein);
      frontBuffer.current.classList.remove('fadeout');
    }

    frontBuffer.current.addEventListener('transitionend', afterFadeout);
    frontBuffer.current.classList.add('fadeout');
  }

  useEffect(() => {
    if(text) {
      if(!isTransitioning.current) {
        crossfadeText(foregroundBuffer, text);
      } else {
        foregroundBuffer.current.classList.remove('fadeout');
        foregroundBuffer.current.style.opacity = getComputedStyle(foregroundBuffer.current).opacity;
        requestAnimationFrame(() => {
          foregroundBuffer.current.style.opacity = "";

          isTransitioning.current = false;
          crossfadeText(foregroundBuffer, text);
        });
      }
    }
  }, [text]);

  return (
    <div className={`${className} smooth-text`} ref={outerRef} key={name}>
      <div className="front" ref={foregroundBuffer}></div>
    </div>
  )
}

export default SmoothText;