import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'gatsby'


const TabbedAccordion = ({index, tab}) => {

  const contentRef = useRef();
  const [isActive, setIsActive] = useState(index === 0 ? true : false);
  const [contenthHeight, setContentHeight] = useState('0px');

  const handleAccordionClick = () => {
    if (!isActive) {
      setIsActive(true)
      setContentHeight(`${contentRef.current.scrollHeight}px`)
    } else if (isActive) {
      setIsActive(false)
      setContentHeight(`0px`)
    }
  }
  useEffect( () => {
    setContentHeight(index === 0 ? `${contentRef.current.scrollHeight}px` : '0px')
  }, [])


  return (
    <div className="tabs-section">
      <button
        className={`h5 accordion tab ${isActive ? "active-tabs" : ""}`}
        onClick={handleAccordionClick}
      >
        <span className="text">{tab.tabLabel}</span>
        <span className={`plus ${isActive ? '' : 'show'}`}></span>
        <span className={`minus ${isActive ? 'show' : ''}`}></span>
      </button>
      <div
        className="content-tabs"
        ref={contentRef}
        style={{ maxHeight: `${contenthHeight}` }}
      >
        <div className={`content`}>
          {tab.introCopy && 
            <div className="tab-content--intro">
              <p className="p2 sans">
                {tab.introCopy}
              </p>
              {tab.button && tab.button.map( (introButton, buttonIdx) => {
                switch (introButton.fieldGroupName) {
                  case 'Page_Tertiarypages_PageContent_TabbedContent_tabContent_Button_Link' :
                    return <Link to={introButton.url} target={introButton.target} className={`button`} key={buttonIdx}>{introButton.title}</Link>
                  case 'Page_Tertiarypages_PageContent_TabbedContent_tabContent_Button_File' :
                    return <a href={introButton.file.mediaItemUrl} target="_blank" className="button" rel="noreferrer" download key={buttonIdx}>{introButton.buttonLabel}</a>
                  default : 
                    return <p className="p3">Unfortunately we could not match this module</p>
                }
              })}
              
            </div>
          }
          
          {tab.contentBlock.map( (block, blockIdx, tab) => {
            return (
              <div className="tab-content--sectional" key={blockIdx}>
                <h2 className="h2 sans">{block.sectionalHeader}</h2>
                <div className="three-up-cards">
                  <div className="container">
                    {block.sectionalContent.map( (section, sectionIdx) => {
                      return (
                        <div className={`tabbed-three-up tabbed-three-up-${sectionIdx}`}>
                          <h5 className="eyebrow eyebrow--big">{section.header}</h5>
                          <p className="p3">{section.copy}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {block.quote && 
                  <div className={`tab-content--quote basic-quote basic-quote--${block.quoteSize}`}>
                    <h2 className={block.quoteSize}>{block.quote}</h2>
                    {block.attribute &&
                      <div className="attribution">—{block.attribute}</div>
                    }
                  </div>
                }
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TabbedAccordion;