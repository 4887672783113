import React from "react"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

const timeout = 600
const getTransitionStyles = {
  entering: {
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-out`,
    opacity: 0,
  },
}

class PageTransition extends React.PureComponent {
  render() {
    const { children, location } = this.props
    if (location.hash === '')  {
      return (
        <TransitionGroup component={null}>
          <ReactTransition
            mountOnEnter={true}
            unmountOnExit={false}
            key={location ? `transition-${location.pathname}` : `transition-${Date.now()}`}
            timeout={{
              enter: timeout,
              exit: timeout,
            }}
  
          >
            {status => (
              <div className="page-transition"
                style={{
                  ...getTransitionStyles[status],
                }}
              >
                {children}
              </div>
            )}
          </ReactTransition>
        </TransitionGroup>
      )
    } else {
      return <div>{children}</div>
    }
  }
}

export default PageTransition