import React, { useRef, useState, useEffect} from "react"

const DocumentsAccordionInner = ({document, copy, TLindex, SLindex, length, setHeightTopLevel, refContentTopLevel}) => {

  const refContentSecondLevel = useRef(null);
  const [isActiveSecondLevel, setIsActiveSecondLevel] = useState(SLindex === 0 ? true : false);
  const [heightSecondLevel, setHeightSecondLevel] = useState('0px');
  const [buttonActive, setButtonActive] = useState(SLindex === 0 ? true : false);

  const handleInnerAccordionClick = () => {
    if (!isActiveSecondLevel) {
      setHeightTopLevel(`${refContentTopLevel.current.scrollHeight + refContentSecondLevel.current.scrollHeight}px`)
      setIsActiveSecondLevel(true)
      setHeightSecondLevel(`${refContentSecondLevel.current.scrollHeight}px`)
      setButtonActive(true);
    } else if (isActiveSecondLevel) {
      setIsActiveSecondLevel(false)
      setHeightTopLevel(`${refContentTopLevel.current.scrollHeight}px`)
      setHeightSecondLevel(`0px`)
      setButtonActive(false);
    }
  }

  useEffect( () => {
    if (TLindex === 0 && SLindex === 0) {
      setHeightTopLevel(`${refContentTopLevel.current.scrollHeight + refContentSecondLevel.current.scrollHeight}px`);
      setHeightSecondLevel(`${refContentSecondLevel.current.scrollHeight}px`);
    }
  }, [])

  if (document.subhead) {
    return ( // subhead and links
      <>
        <button
          className={`eyebrow eyebrow--small accordion__title ${buttonActive ? 'active' : ''}`}
          onClick={handleInnerAccordionClick}
        >{document.subhead}<span></span></button>

        <div
          key={SLindex}
          className={`links ${copy ? 'accordion__content' : ''} ${SLindex === length - 1 ? 'last-child' : ''}`}
          ref={refContentSecondLevel}
          style={{ maxHeight: `${heightSecondLevel}`}}
        >
          {document.document.map( (document, idx) => {
            switch (document.__typename) {
              case "WpPage_Tertiarypages_PageContent_Resources_documentsBlock_documents_Document_File" :
                const fileType = document.download.mimeType === "application/zip" ? '.ZIP' : 'PDF'
                return (
                  <a href={document.download.mediaItemUrl} key={idx} target="_blank" rel="noreferrer" download>
                    <span><img src="/file-icon.svg" alt="file icon" /></span>
                    <span>{document.fileName} ({fileType})</span>
                  </a>
                )
              case "WpPage_Tertiarypages_PageContent_Resources_documentsBlock_documents_Document_Link" :
                const setRel = document.link.target ? 'noreferrer' : '';
                return (
                  <a href={document.link.url} target={document.link.target} rel={setRel} key={idx}>
                    <span className="link"><img src="/link-icon.svg" alt="link icon" /></span>
                    <span>{document.link.title}</span>
                  </a>
                )
              default :
                  return <p className='p3'>Sorry, we could not find that resource</p>
            }

          })}
        </div>
      </>
    )
  } else {
    return ( // just links
      <div
        className={`links`}  
        ref={refContentSecondLevel}
      >
        {document.document.map( (document, idx) => {
          switch (document.__typename) {
            case "WpPage_Tertiarypages_PageContent_Resources_documentsBlock_documents_Document_File" :
              const fileType = document.download.mimeType === "application/zip" ? '.ZIP' : 'PDF'
              return (
                <a href={document.download.mediaItemUrl} key={idx} target="_blank" rel="noreferrer" download>
                  <span><img src="/file-icon.svg" alt="file icon" /></span>
                  <span>{document.fileName} ({fileType})</span>
                </a>
              )
            case "WpPage_Tertiarypages_PageContent_Resources_documentsBlock_documents_Document_Link" :
              const setRel = document.link.target ? 'noreferrer' : '';
              return (
                <a href={document.link.url} target={document.link.target} rel={setRel} key={idx}>
                  <span><img src="/link-icon.svg" alt="link icon" /></span>
                  <span>{document.link.title}</span>
                </a>
              )
            default :
                return <p className='p3'>Sorry, we could not find that resource</p>
          }

        })}
      </div>
    )
  }
}

export default DocumentsAccordionInner;