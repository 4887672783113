import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const {wp: {storyNavigation: {storyNavigationGlobals: {headerNavigationItems}}}} = useStaticQuery(graphql`
    {
      wp {
        storyNavigation {
          storyNavigationGlobals {
            headerNavigationItems {
              headerNavItem {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const handleScroll = event => {
    if (document.getElementById('refugee-experience')) {
      event.preventDefault();
      document.getElementById('refugee-experience').scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }
  return (
    <footer>
      <p className="nav__item">© {new Date().getFullYear()}
        {` `}
        <a href="https://www.resistancecommunications.com/">Resistance Communications</a>
      </p>
      <ul>
        {headerNavigationItems.map( (item, index) => {
          if (index === 0) {

            return (
              <li className="nav__item" key={index}><a onClick={handleScroll} href={item.headerNavItem.url}>{item.headerNavItem.title}</a></li>
            )
          } else {
            return (
              <li className="nav__item" key={index}><Link to={item.headerNavItem.url}>{item.headerNavItem.title}</Link></li>
            )
          }
        })}
      </ul>
    </footer>
  )
}

export default Footer;