import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import AccordionMenu from "./ring-navigation/accordion-menu";
import RingNavigation from "./ring-navigation/ring-navigation"
import StoryBodyDesktop from "./ring-navigation/story-body-desktop"
import SectionsModalMobile from "./ring-navigation/sections-modal-mobile"
import BackgroundImageCrossfade from "./ring-navigation/background-image-crossfade"

const parseSections = (storyNavigationGlobals) => {
  let sections = [];
  sections.push({
    items: storyNavigationGlobals.storyOneItems,
    title: storyNavigationGlobals.storyOne,
    description: storyNavigationGlobals.storyOneDescription,
    backgroundImage: storyNavigationGlobals.storyOneBackground.localFile.publicURL,
    backgroundImageColor: storyNavigationGlobals.storyOneBackgroundColor.localFile.publicURL,
    navBackground: `rgba(65, 210, 175, 0.4)`,
    pieVariant: 'halves',
  });
  sections.push({
    items: storyNavigationGlobals.storyThreeItems,
    title: storyNavigationGlobals.storyThree,
    description: storyNavigationGlobals.storyThreeDescription,
    backgroundImage: storyNavigationGlobals.storyThreeBackground.localFile.publicURL,
    backgroundImageColor: storyNavigationGlobals.storyThreeBackgroundColor.localFile.publicURL,
    navBackground: `rgba(92, 128, 238, 0.4)`,
    pieVariant: 'sixths'
  });
  sections.push({
    items: storyNavigationGlobals.storyTwoItems,
    title: storyNavigationGlobals.storyTwo,
    description: storyNavigationGlobals.storyTwoDescription,
    backgroundImage: storyNavigationGlobals.storyTwoBackground.localFile.publicURL,
    backgroundImageColor: storyNavigationGlobals.storyTwoBackgroundColor.localFile.publicURL,

    navBackground: `rgba(252, 76, 59, 0.4)`,
    pieVariant: 'sevenths'
  });

  return sections;
}

const RefugeeExperience = () => {

  const {wp: {storyNavigation: {storyNavigationGlobals}}} = useStaticQuery(graphql`
  {
    wp {
      storyNavigation {
        storyNavigationGlobals {
          ringNavTitle
          ringNavDescription
          ringNavCenterText
          storyOne
          storyOneDescription
          storyOneBackground {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                gatsbyImageData
              }
            }
          }
          storyOneBackgroundColor {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                gatsbyImageData
              }
            }
          }
          storyOneItems {
            navItem
            story {
              ... on WpPost {
                id
                uri
                title
                categories {
                  nodes {
                    slug
                  }
                }
                storytellingPage {
                  fieldGroupName
                  navContent {
                    quote
                    attribution
                    description
                    centerCircleText
                    audioFile {
                      caption
                      localFile {
                        publicURL
                        relativePath
                        url
                      }
                    }
                    backgroundImage {
                      localFile {
                        relativePath
                        publicURL
                      }
                    }
                  }
                }
              }
            }
          }
          storyTwo
          storyTwoDescription
          storyTwoBackground {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                gatsbyImageData
              }
            }
          }
          storyTwoBackgroundColor {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                gatsbyImageData
              }
            }
          }
          storyTwoItems {
            navItem
            story {
              ... on WpPost {
                id
                uri
                title
                categories {
                  nodes {
                    slug
                  }
                }
                storytellingPage {
                  navContent {
                    quote
                    attribution
                    description
                    centerCircleText
                    audioFile {
                      caption
                      localFile {
                        publicURL
                        relativePath
                        url
                      }
                    }
                    backgroundImage {
                      localFile {
                        relativePath
                        publicURL
                      }
                    }
                  }
                }
              }
            }
          }
          storyThree
          storyThreeDescription
          storyThreeBackground {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                gatsbyImageData
              }
            }
          }
          storyThreeBackgroundColor {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
                gatsbyImageData
              }
            }
          }
          storyThreeItems {
            navItem
            story {
              ... on WpPost {
                id
                uri
                title
                categories {
                  nodes {
                    slug
                  }
                }
                storytellingPage {
                  navContent {
                    quote
                    attribution
                    description
                    centerCircleText
                    audioFile {
                      caption
                      localFile {
                        publicURL
                        relativePath
                        url
                      }
                    }
                    backgroundImage {
                      localFile {
                        relativePath
                        publicURL
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

  const defaultBackgroundUrl = "/Navigation_Home.jpg";
  const sections = parseSections(storyNavigationGlobals);
  const KEY_SAVE_STATE = "ring_save_state";

  const [activeSection, setActiveSection] = useState(-1);
  const [activeStory, setActiveStory] = useState(null);
  const [backgroundUrl, setBackgroundUrl] = useState(defaultBackgroundUrl);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isBackNavigation, setIsBackNavigation] = useState(false);

  const handleRestorePreviousState = () => {
    const state = window.history.state;
    if(state && state.story) {
      //When restoring state (on navigating back from story)
      //we don't want the circle to animate and jump around abruptly
      document.querySelectorAll('.navigation, .pie, .center-circle, .pie--second-state').forEach((node) => {
        node.style.transitionDuration = '0s';
      });

      //Wait for one frame for the browser to reflow.
      //Then update all the states and finally restore transitions
      requestAnimationFrame(() => {
        handleSectionClicked(state.section, state.section !== -1, state.section > -1 ? sections[state.section] : null);
        handleStorySelected(state.story);
        window.history.replaceState({}, KEY_SAVE_STATE);

        //Return to normal
        document.querySelectorAll('.navigation, .pie, .center-circle, .pie--second-state').forEach((node) => {
          node.style.transitionDuration = '';
        });
      });
    }
  };

  const handleStartOver = () => {
    setActiveSection(-1);
    setActiveStory(null);
    setBackgroundUrl(defaultBackgroundUrl);
    setModalOpen(false);
  }

  const handleSectionClicked = (index, isActive, sectionData) => {
    if(isActive) {
      setActiveSection(index);
      setActiveStory(null);
      setBackgroundUrl(sectionData.backgroundImageColor);
      setModalOpen(true);
    } else {
      handleStartOver();
    }
  }

  const handleWedgeHoverStart = (section) => {
    if(activeSection < 0) {
      setBackgroundUrl(section.backgroundImage);
    }
  }

  const handleWedgeHoverEnd = () => {
    if(activeSection < 0) {
      setBackgroundUrl(defaultBackgroundUrl);
    }
  }

  const handleStorySelected = (story) => {
    setActiveStory(story);
    if(story) {
      setBackgroundUrl(story.storytellingPage.navContent.backgroundImage.localFile.publicURL);
    }
  }

  const handleNavigateToStory = () => {
    window.history.pushState({
      section: activeSection,
      story: activeStory
    }, KEY_SAVE_STATE);
  }

  useEffect(() => {
    handleRestorePreviousState();
  }, []);

  return(
    <div>
      <div className='ring-nav' id="refugee-experience">
        <BackgroundImageCrossfade url={backgroundUrl} useBlur={!!activeStory} id="desktop-bg" />
        <h2 className="h1 title">{storyNavigationGlobals.ringNavTitle}</h2>
        <p className="p3 description">{storyNavigationGlobals.ringNavDescription}</p>
        <AccordionMenu
          sections={sections}
          activeSection={activeSection}
          onStoryClick={(story) => {handleStorySelected(story)}}
          onSectionClick={(index, isActive, sectionData) => handleSectionClicked(index, isActive, sectionData)}/>
        <div className={`nav-container ${activeStory ? 'third-state-active' : ''}`} id="nav-container">
          <RingNavigation
            activeStory={activeStory}
            onSectionClick={(index, sectionData) => {handleSectionClicked(index, true, sectionData)}}
            onHoverStart={(section) => handleWedgeHoverStart(section)}
            onHoverEnd={() => handleWedgeHoverEnd()}
            centerText={storyNavigationGlobals.ringNavCenterText}
            sections={sections}
            activeSection={activeSection}
            onStoryClick={(story) => {handleStorySelected(story)}}
            onStartOver={() => handleStartOver()}/>
          <StoryBodyDesktop
            selectedStoryContent={activeStory}
            onNavigateToStory={() => {handleNavigateToStory()}}
          />
        </div>
        {/* Mobile Second state */}
        <SectionsModalMobile
          show={isModalOpen}
          activeStory={activeStory}
          onStoryClick={(story) => {handleStorySelected(story)}}
          activeSection={activeSection > -1 ? sections[activeSection] : null}
          onNavigateToStory={() => handleNavigateToStory()}
          onClose={() => {handleStartOver()}}
        />
      </div>
    </div>
  )
}

export default React.memo(RefugeeExperience);