import React, { useEffect, useState } from "react"
import SmoothText from "./smooth-text"
import { CSSTransition, SwitchTransition } from "react-transition-group"

const PieHalves = ({ onStoryClick, activeStory, isActive, section }) => {

  const storyText = activeStory ? activeStory.storytellingPage.navContent.centerCircleText : section.description;

  const [centerText, setCenterText] = useState(storyText);
  const [showCta, setShowCta] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const shallowCompare = (obj1, obj2) => {
    return Object.keys(obj1).length === Object.keys(obj2).length &&
      obj1.id === obj2.id && obj1.uri && obj2.uri && obj1.title === obj2.title;
  }

  const isSelected = (item) => {
    if(activeStory === null) {
      return false;
    }

    return shallowCompare(item.story[0], activeStory);
  }

  useEffect(() => {
    if(activeStory === null) {
      setSelectedIndex(-1);
      return;
    }

    section.items.forEach((item, i) => {
      if(isSelected(item)) {
        setSelectedIndex(i);
      }
    });
  }, [activeStory]);

  return (
    <ul className={`pie pie--halves pie--second-state ${isActive ? 'active' : ''}`} id="pie-second-state-journey">
      {section.items.map((item, index) => {

        return <div key={index}>
          <li
            style={{background: index === selectedIndex ? section.navBackground : ''}}
            className={`pie--wedge pie--wedge__second half half--${index}`}
            id={item.story[0].id}>
            <button
              onClick={() => {
              if(onStoryClick) onStoryClick(item.story[0]);
              }}
              onMouseEnter={() => {
                setCenterText(item.story[0].storytellingPage.navContent.centerCircleText);
                setShowCta(false);
              }}
              onMouseLeave={() => {
                setCenterText(storyText);
                setShowCta(true);
              }}>
              <span>{item.navItem}</span>
            </button>
          </li>
          <div className={`half--border half--border__${index}`}></div>
        </div>
      })}
      <div className="center-text">
        <SmoothText name="halves" text={centerText} showCta={showCta} />
      </div>
    </ul>
  )
}

export default PieHalves;