import React from "react"
import { Link } from "gatsby"
import Audio from '../../helpers/audio';
import BackgroundImageCrossfade from "./background-image-crossfade"

const StoryBodyMobile = ({activeSection, activeStory, onBackClick, onStoryClick, onNavigateToStory}) => {

  const getNextStory = (story) => {
    for(let i = 0; i < activeSection.items.length; i++) {
      if(activeSection.items[i].story[0] === story) {
        return activeSection.items[(i + 1) % activeSection.items.length].story[0];
      }
    }

    return null;
  }

  const handleNextStoryClicked = () => {
    if(onStoryClick) {
      onStoryClick(nextStory);
    }
  }

  //Make sure that when this component unmounts
  //the scrolling behavior is reset to normal
  // useEffect(() => {
  //   return () => {
  //     onBackClick
  //   }
  // });

  if(!activeStory || !activeSection) {
    return (<div />);
  }

  const nextStory = getNextStory(activeStory);
  const buttonCtaText = `learn more about the ${activeStory.title}`;
  const storyColor = activeStory.categories.nodes.length > 0 ? activeStory.categories.nodes[0].slug : '';
  const backgroundImage = activeStory.storytellingPage.navContent.backgroundImage.localFile.publicURL;

  return (
    <div className="state-third modal">
      <div className="state-third--content">
        <BackgroundImageCrossfade url={backgroundImage} useBlur={true} />
        <div className="nav-buttons">
          <button
            className="back text-cta"
            onClick={() => {
              if(onBackClick) {
                onBackClick();
              }
            }}>
            Back</button>
        </div>
        <div className={`content ${activeStory ? 'opacity' : ''}`} id="third-state-content">
          <p className="eyebrow eyebrow--big">{activeStory.title}</p>

          { activeStory.storytellingPage.navContent.audioFile &&
            <Audio src={activeStory.storytellingPage.navContent.audioFile.localFile.publicURL} color={storyColor ? storyColor : ''} linePosition="bottom desktop--left" />
          }

          <h3 className="nav-story--quote">{activeStory.storytellingPage.navContent.quote}</h3>

          { activeStory.storytellingPage.navContent.attribution &&
            <p className="attribution">{activeStory.storytellingPage.navContent.attribution}</p>
          }

          { activeStory.storytellingPage.navContent.description &&
            <p className="p3">{activeStory.storytellingPage.navContent.description}</p>
          }

          <Link
            className={`button button--${storyColor ? storyColor : ''}`}
            onClick={() => {onNavigateToStory()}}
            to={activeStory.uri}>{buttonCtaText}</Link>
        </div>
        { nextStory ?
          <button
            onClick={() => {
              handleNextStoryClicked();
            }}
            className="next text-cta">Next {activeSection.topLevelStory}: {nextStory.title}
          </button>
          : ''
        }

      </div>
    </div>
  );
}

export default StoryBodyMobile;
