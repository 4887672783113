import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import SecondaryHero from "../modules/secondary-hero"

import BasicQuote from "../modules/basic-quote"
import CopyImage from "../modules/copy-image"
import HorizontalImageCopy from "../modules/horizontal-image-copy"
import OffsetQuote from "../modules/quote-offset"
import CopyStacked from "../modules/copy-stacked"
import CopySide from "../modules/copy-side"
import QuoteImageOverlap from "../modules/quote-image-overlap"
import QuoteImageSideBySide from "../modules/quote-image-side-by-side"
import HowToHelp from "../modules/how-to-help"
import NextStoryLink from "../modules/next-story-link"
import ThreeUpCards from "../modules/three-up-cards"
import SecondaryNavigation from "../components/secondary-navigation/secondary-navigation"
import PageAnchor from "../modules/page-anchor"
import TableOfContents from "../modules/table-of-contents"

const BlogPostTemplate = ({ data: { post }, location }) => {
  let highestzIndex = post.storytellingPage.pageContent.length + 1;

  const getModule = (post) => {  
    return post.storytellingPage.pageContent.map((module, idx, modules) => {

      let output;
      const reverseZindex = modules.length-idx;

      switch (module.__typename) {
        case "WpPost_Storytellingpage_PageContent_AnchorLink":
          return <PageAnchor
                    title={module.linkTitle}
                  />
        case "WpPost_Storytellingpage_PageContent_BasicQuote":
          output = <BasicQuote
            reverseZindex={reverseZindex}
            quote={module.quote}
            attribute={module.attribute}
            quoteSize={module.quoteSize}
            backgroundImage={module.backgroundImage}
          />
          break

        case "WpPost_Storytellingpage_PageContent_CopyImage":
          output = <CopyImage
            reverseZindex={reverseZindex}
            pinline={module.pinline}
            copy={module.copy}
            hasTooltip={module.addATooltip}
            tooltip={module.tooltipText}
            image={module.image}
            imageAlign={module.imageAlign}
            imageSize={module.imageSize}
            ctaButton={module.ctaButton}
            storyColor={post.categories.nodes[0].slug}
          />
          break
        case 'WpPost_Storytellingpage_PageContent_HorizontalImageCopy' :
          output =  <HorizontalImageCopy
                    reverseZindex={reverseZindex}
                    copy={module.copy}
                    hasTooltip={module.addATooltip}
                    tooltip={module.tooltipText}
                    image={module.image}
                    imageSize={module.imageSize}
                    cta={module.cta}
                    storyColor={post.categories.nodes[0].slug}
                />
          break
        case "WpPost_Storytellingpage_PageContent_OffsetQuote":
          output = <OffsetQuote
            reverseZindex={reverseZindex}
            quote={module.quote}
            attribution={module.attribution}
            audio={module.audioFile}
            eyebrow={module.eyebrow}
            quoteAlign={module.quoteAlign}
            quoteSize={module.quoteSize}
            storyColor={post.categories.nodes[0].slug}
          />
          break
        case "WpPost_Storytellingpage_PageContent_CopyStacked":
          output = <CopyStacked
            reverseZindex={reverseZindex}
            bodyCopy={module.bodyCopy}
            hasTooltip={module.addATooltip}
            tooltip={module.tooltipText}
            ctaButton={module.ctaButton}
            headlineSelect={module.headlineSelect}
            storyColor={post.categories.nodes[0].slug}
          />
          break
        case "WpPost_Storytellingpage_PageContent_CopySideBySide":
          output = <CopySide
            reverseZindex={reverseZindex}
            column2={module.bodyCopy}
            hasTooltip1={module.addATooltipToColumn1}
            tooltip1={module.tooltipTextColumn1}
            hasTooltip2={module.addATooltipToColumn2}
            tooltip2={module.tooltipTextColumn2}
            column1={module.headline}
            ctaButtonColumn1={module.ctaButtonColumn1}
            ctaButton={module.ctaButton}
            storyColor={post.categories.nodes[0].slug}
            pinlineColumn1={module.pinlineColumn1}
            pinlineColumn2={module.pinlineColumn2}
          />
          break
        case "WpPost_Storytellingpage_PageContent_QuoteImageOverlap":
          output = <QuoteImageOverlap
            reverseZindex={reverseZindex}
            attribution={module.attribution}
            eyebrow={module.eyebrow}
            image={module.image}
            imageAlign={module.imageAlign}
            quote={module.quote}
          />
          break
        case "WpPost_Storytellingpage_PageContent_QuoteImageSideBySide":
          output = <QuoteImageSideBySide
            reverseZindex={reverseZindex}
            attribution={module.attribution}
            eyebrow={module.eyebrow}
            image={module.image}
            imageAlign={module.imageAlign}
            quote={module.quote}
          />
          break
        case "WpPost_Storytellingpage_PageContent_CtaBox":
          output = <HowToHelp
            reverseZindex={reverseZindex}
            bodyCopy={module.bodyCopy}
            hasTooltip={module.addATooltip}
            tooltip={module.tooltipText}
            ctaButton={module.ctaButton}
            headline={module.headline}
            storyColor={post.categories.nodes[0].slug}
          />
          break
        case "WpPost_Storytellingpage_PageContent_NextStory":
          output = <NextStoryLink
            eyebrow={module.eyebrow}
            nextLink={module.nextLink}
          />
          break
        case "WpPost_Storytellingpage_PageContent_3UpEndCards":
          let isFullWidth = false;
          if (idx > 0) { // check if any modules exist
            const previousModule = modules[idx - 1]; // check if full width content exists
            if (previousModule.__typename === "WpPost_Storytellingpage_PageContent_BasicQuote" && previousModule.backgroundImage) {
              isFullWidth = true;
            }
          }

          output = <ThreeUpCards
            reverseZindex={reverseZindex}
            copy1={module.bodyCopy1}
            copy2={module.bodyCopy2}
            copy3={module.bodyCopy3}
            cta1={module.ctaButton1}
            cta2={module.ctaButton2}
            cta3={module.ctaButton3}
            header1={module.header1}
            header2={module.header2}
            header3={module.header3}
            isFullWidth={isFullWidth} //variable for show/hide divider line
          />
          break
        default:
          output = <p className="p3">We could not find that module</p>
          break
      }

      return output;
    });
  }

  return (
    <Layout>
      <Seo title={post.seo.title} description={post.seo.metaDesc} />
      <SecondaryNavigation location={location} />
      <SecondaryHero
        eyebrow={post.storytellingPage.eyebrow}
        headline={post.storytellingPage.headline}
        background={post.storytellingPage.backgroundImage}
        middleground={post.storytellingPage.middlegroundImage}
        foreground={post.storytellingPage.foregroundImage}
      />

      {post.storytellingPage.tableOfContents && 
        <TableOfContents
          reverseZindex={highestzIndex}
          introCopy={post.storytellingPage.introCopyToc.introCopy}
          hasTooltip={post.storytellingPage.introCopyToc.addATooltip}
          tooltip={post.storytellingPage.introCopyToc.tooltipText}
          introCTA={post.storytellingPage.introCopyToc.introCta}
          tocTitle={post.storytellingPage.introCopyToc.tableOfContentsTitle}
          modules={post.storytellingPage.pageContent}
          location={location}
        />
      }


      {getModule(post)}

    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      slug
      seo {
        metaDesc
        title
      }
      categories {
        nodes {
          slug
        }
      }
      storytellingPage {
        eyebrow
        headline
        backgroundImage {
          localFile {
            childImageSharp {
              fluid {
                src
                base64
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
        middlegroundImage {
          localFile {
            childImageSharp {
              fluid {
                src
                base64
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
        foregroundImage {
          localFile {
            childImageSharp {
              fluid {
                src
                base64
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
        tableOfContents
        introCopyToc {
          introCopy
          addATooltip
          tooltipText
          introCta {
            title
            url
          }
          tableOfContentsTitle
        }
        pageContent {
          __typename
          ... on WpPost_Storytellingpage_PageContent_AnchorLink {
            linkTitle
          }
          ... on WpPost_Storytellingpage_PageContent_BasicQuote {
            attribute
            backgroundImage {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                    src    
                  }
                }
              }
            }
            quote
            quoteSize
          }
          ... on WpPost_Storytellingpage_PageContent_CopyImage {
            pinline
            copy
            addATooltip
            tooltipText
            ctaButton {
              title
              url
              target
            }
            imageAlign
            imageSize
            image {
              altText
              localFile { publicURL }
            }
          }
          ... on WpPost_Storytellingpage_PageContent_HorizontalImageCopy {
            copy
            addATooltip
            tooltipText
            cta {
              title
              url
              target
            }
            imageSize
            image {
              altText
              localFile { publicURL }
            }
          }
          ... on WpPost_Storytellingpage_PageContent_OffsetQuote {
            attribution
            audioFile {
              caption
              localFile {
                publicURL
              }
            }
            eyebrow
            quote
            quoteAlign
            quoteSize
          }
          ... on WpPost_Storytellingpage_PageContent_CopyStacked {
            bodyCopy
            addATooltip
            tooltipText
            ctaButton {
              title
              url
              target
            }
          }
          ... on WpPost_Storytellingpage_PageContent_CopySideBySide {
            bodyCopy
            addATooltipToColumn1
            tooltipTextColumn1
            headline
            addATooltipToColumn2
            tooltipTextColumn2
            ctaButton {
              title
              url
              target
            }
            ctaButtonColumn1 {
              title
              url
              target
            }
            pinlineColumn1
            pinlineColumn2

          }
          ... on WpPost_Storytellingpage_PageContent_QuoteImageOverlap {
            attribution
            eyebrow
            image {
              altText
              localFile {
                publicURL
              }
            }
            imageAlign
            quote
          }
          ... on WpPost_Storytellingpage_PageContent_CtaBox {
            bodyCopy
            addATooltip
            tooltipText
            ctaButton {
              title
              url
              target
            }
            headline
          }
          ... on WpPost_Storytellingpage_PageContent_NextStory {
            eyebrow
            nextLink {
              title
              url
            }
          }
          ... on WpPost_Storytellingpage_PageContent_3UpEndCards {
            bodyCopy1
            bodyCopy2
            bodyCopy3
            ctaButton1 {
              title
              url
              target
            }
            ctaButton2 {
              title
              url
              target
            }
            ctaButton3 {
              title
              url
              target
            }
            header1
            header2
            header3
          }
          ... on WpPost_Storytellingpage_PageContent_QuoteImageSideBySide {
            attribution
            eyebrow
            image {
              altText
              localFile {
                publicURL
              }
            }
            imageAlign
            quote
          }
        }
      }
    }
  }
`
