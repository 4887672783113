import React, {useRef} from "react"
import ScrollAnimation from "../helpers/scrollAnimations"
import ComponentWrapper from "../components/wrapper"
import RecsAccordionItem from "../helpers/recs-accordion-item"
import ToolTip from "./tooltip"

const Accordion = ({reverseZindex, intro, items, hasTooltip, tooltip, storyColor='white'}, ref) => {
  const parentCopyEl = useRef(null);

  return (
    <ComponentWrapper>
      <div className="recs-accordion">
        <ScrollAnimation zIndex={reverseZindex}>
          <div className="accordion__intro" ref={parentCopyEl} dangerouslySetInnerHTML={{__html: intro}} />
          {hasTooltip && <ToolTip parentCopy={parentCopyEl} tooltip={tooltip} storyColor={storyColor} />}
          <div className="accordion__items">
            {items.map((item, index) => {
              return (
                <RecsAccordionItem item={item} index={index} key={index} hasTooltip={item.addATooltip} tooltip={item.tooltipText} />
              )
            })}
          </div>
        </ScrollAnimation>
      </div>
    </ComponentWrapper>
  )
}

export default Accordion