import React, { useEffect, useRef } from "react"

const BackgroundImageCrossfade = ({ url, useBlur = false, id = "" }) => {

  const backgroundBuffer = useRef();
  const foregroundBuffer = useRef();
  const isTransitioning = useRef(false);

  const crossfadeImage = (backBuffer, frontBuffer, url) => {

    isTransitioning.current = true;

    const afterFadein = () => {
      frontBuffer.current.removeEventListener('transitionend', afterFadein);
      backBuffer.current.classList.add('fadeout');
      isTransitioning.current = false;
    }

    const afterFadeout = () => {
      frontBuffer.current.removeEventListener('transitionend', afterFadeout);
      frontBuffer.current.src = url;
      frontBuffer.current.addEventListener('transitionend', afterFadein);
      frontBuffer.current.classList.remove('fadeout');
    }

    const afterImageLoaded = () => {
      backBuffer.current.removeEventListener('load', afterImageLoaded);
      backBuffer.current.classList.remove('fadeout');
      frontBuffer.current.addEventListener('transitionend', afterFadeout);
      frontBuffer.current.classList.add('fadeout');
    }

    frontBuffer.current.classList.remove('fadeout');
    backBuffer.current.classList.add('fadeout');
    backBuffer.current.addEventListener('load', afterImageLoaded);
    backBuffer.current.src = '';
    backBuffer.current.src = url;
  }

  useEffect(() => {
    if(url) {
      if(!isTransitioning.current) {
        crossfadeImage(backgroundBuffer, foregroundBuffer, url);
      } else {
        foregroundBuffer.current.classList.remove('fadeout');
        backgroundBuffer.current.classList.add('fadeout');
        foregroundBuffer.current.style.opacity = getComputedStyle(foregroundBuffer.current).opacity;
        backgroundBuffer.current.style.opacity = getComputedStyle(backgroundBuffer.current).opacity;
        requestAnimationFrame(() => {
          foregroundBuffer.current.style.opacity = "";
          backgroundBuffer.current.style.opacity = "";

          isTransitioning.current = false;
          crossfadeImage(backgroundBuffer, foregroundBuffer, url);
        });
      }
    }
  }, [url]);

  return(
    <div className="background-image-crossfade" id={id}>
      <img className="bg-buffer-back fadeout" alt='' ref={backgroundBuffer}></img>
      <img className="bg-buffer-front" alt='' ref={foregroundBuffer} id={`${id}-front`}></img>
      <div className={`blur-mask ${useBlur ? 'blurry' : ''}`} />
    </div>
  )
}

export default BackgroundImageCrossfade;