import React, {useRef, useEffect} from "react"
import ToolTip from "../modules/tooltip";
import { CSSTransition } from "react-transition-group";

const RecsAccordionItem = ({item, index, hasTooltip=false, tooltip, storyColor='white'}, ref) => {
  
  const [isExpanded, setIsExpanded] = React.useState(false);
  const refContent = useRef(null);
  const accordionHeight = (isExpanded ? `${refContent.current.scrollHeight}px` : "0px");
  
  const handleAccordionClick = () => {
    setIsExpanded(!isExpanded);
  }
  
  useEffect(() => {
    window.addEventListener('resize', () => setIsExpanded(false));
    return () => {
      window.removeEventListener('resize', () => setIsExpanded);
    }
  })

  return (
    <div className="accordion__item" key={index}>
      <div className="accordion__item-title" onClick={handleAccordionClick}>
        <p className="eyebrow eyebrow--small">{item.header}</p>
        <span className="nav-plus">
          <span className={`nav-plus__lines ${isExpanded ? 'expanded': ''}`}></span>
          <span className="nav-plus__lines"></span>
        </span>
      </div>
      <div className="accordion__content" ref={refContent} style={{ maxHeight: `${accordionHeight}`}} dangerouslySetInnerHTML={{__html: item.content}} />
      {hasTooltip &&
        <CSSTransition  in={isExpanded} timeout={800} classNames={`accordion-tooltip--animation`}> 
          <ToolTip parentCopy={refContent} tooltip={tooltip} storyColor={storyColor} />
        </CSSTransition>
      }
    </div>
  );
}

export default RecsAccordionItem;