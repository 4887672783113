import React, {useEffect, useState}  from 'react';
import {Link, graphql, useStaticQuery} from "gatsby";
import NavDropDown from '../helpers/nav-dropdown';
import { CSSTransition } from 'react-transition-group';

const Header = ({}, ref) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [currentSubNavOpen, setCurrentSubNavOpen] = useState(null);

  const {wp: {storyNavigation: {storyNavigationGlobals: {headerNavigationItems, subNavItems}}, generalSettings}} = useStaticQuery(graphql`
  {
    wp {
      generalSettings {
        title
      }
      storyNavigation {
        storyNavigationGlobals {
          headerNavigationItems {
            headerNavItem {
              title
              url
            }
            subNavItems {
              subNavItem {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`)

  const toggleNav = () => {
    if (isNavOpen) {
      // close nav
      const overlay = document.getElementById('overlay');
      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || 0) * -1);
      body.classList.remove('overlay-open');
      setIsNavOpen(false);
    } else {
      // open nav
      const overlay = document.getElementById('overlay');
      const body = document.body;
      const scrollY = window.pageYOffset;

      body.style.position = 'fixed';
      body.style.top = `-${scrollY}px`;
      body.classList.add('overlay-open');
      setIsNavOpen(true);
    }
  }

  const toggleSubNav = (id) => {
    setCurrentSubNavOpen(currentSubNavOpen !== id ? id : null);
  }


  const handleScroll = event => {
    if (document.getElementById('refugee-experience')) {
      event.preventDefault();
      document.getElementById('refugee-experience').scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }

  const handleResizeEvent = (vh, vw) => {
    // Set view height
    let vhResize = window.innerHeight * 0.01;
    let vwResize = window.innerWidth;
    if (vw !== vwResize && vh !== vhResize) {
      document.documentElement.style.setProperty('--vh', `${vhResize}px`);
      document.documentElement.style.setProperty('--vw', `${vwResize}px`);
    }
    if (isNavOpen) {
      toggleNav();
    }
  }

  let oldPosition = 0
  let newPosition = 0
  const handleScrollEvent = (siteHeader) => {
    newPosition = window.pageYOffset;
    if (oldPosition < newPosition) {
      siteHeader.classList.add('hide');
      siteHeader.classList.remove('show');
    }
    else if (oldPosition > newPosition  && newPosition >= 80) {
      siteHeader.classList.add('show');
      siteHeader.classList.remove('hide');
    }
    else if (newPosition === 0) {
      siteHeader.classList.remove('show');
      siteHeader.classList.remove('hide');
    }
    oldPosition = newPosition <= 0 ? 0 : newPosition; //account for negative / mobile up-scroll
  }

  const handleWindowClick = (e) => {
    if (!e.target.closest('.expanded')) {
      setCurrentSubNavOpen(null);
    }
  };
  
  
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    let vw = window.innerWidth;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', vw);

    window.addEventListener('resize', () => {
      handleResizeEvent(vh, vw);
    });

    const siteHeader = document.getElementById('site-header');
    window.addEventListener('scroll', () => handleScrollEvent(siteHeader));

    window.addEventListener('click', (e) => handleWindowClick(e));

    return () => {
      window.removeEventListener('resize', () => handleResizeEvent);
      window.removeEventListener('scroll', () => handleScrollEvent);
      window.removeEventListener('click', () => handleWindowClick);
    }
  });



  return (
    <nav className="site-header" id="site-header">
      <CSSTransition in={isNavOpen} timeout={1000} classNames={'overlay-transition'} unmountOnExit>
        <div className="overlay" id="overlay">
          <ul>
            {headerNavigationItems.map( (item, index) => {
              if (item.subNavItems) {
                return <NavDropDown key={index} isSubNavOpen={currentSubNavOpen === index} toggleSubNav={toggleSubNav} item={item} index={index} toggleNav={toggleNav} />
              } else {
                return (
                <li className="nav__item" key={index} onClick={() => setCurrentSubNavOpen(null)}><Link onClick={toggleNav} to={item.headerNavItem.url}>{item.headerNavItem.title}</Link></li>
                )
              }
            })}
          </ul>
        </div>
      </CSSTransition>
      <div className="header-navigation">
        <Link
          to="/"
          className="nav__item nav__item--home"
          onClick={() => {
            if (isNavOpen) {
              toggleNav()}}
          }
        >
          {generalSettings.title}
        </Link>
        <div className="hr"></div>
        <ul className="nav">
          {headerNavigationItems.map( (item, index) => {
            index += 100;
            if (item.headerNavItem.url === '/#refugee-experience') {
              return (
                <li className="nav__item" key={index}><a onClick={handleScroll} href={item.headerNavItem.url}>{item.headerNavItem.title}</a></li>
              )
            } else if (item.subNavItems) {
              return (
                <NavDropDown key={index} isSubNavOpen={currentSubNavOpen === index} toggleSubNav={toggleSubNav} item={item} index={index} />
              )
            } else {
              return (
                <li className="nav__item" key={index} onClick={() => setCurrentSubNavOpen(null)}><Link to={item.headerNavItem.url}>{item.headerNavItem.title}</Link></li>
              )
            }
          })}
        </ul>
        <button className="mobile--burger" onClick={toggleNav}>
          <span className="mobile--burger__indicator"></span>
          <span className="mobile--burger__indicator"></span>
        </button>
      </div>
    </nav>
  )
}

export default Header;