import React, { useEffect, useRef, useState } from "react"
import Wedges from "./wedges"
import StartOverButton from "./start-over-button"
import PieThirds from "./pie-thirds"
import PieHalves from "./pie-halves"
import PieSevenths from "./pie-sevenths"
import PieSixths from "./pie-sixths"

const RingNavigation = ({ activeStory, centerText, onStartOver, sections, activeSection, onHoverStart, onHoverEnd, onSectionClick, onStoryClick }) => {

  const [pieVariant, setPieVariant] = useState('thirds');

  const handleStartOver = () => {
    setPieVariant('thirds');

    if(onStoryClick) {
      onStoryClick(null);
    }

    if(onStartOver) {
      onStartOver();
    }
  }

  const handleStoryClicked = (story) => {
    if(onStoryClick) {
      onStoryClick(story);
    }
  }

  useEffect(() => {
    let variant = 'thirds';
    if(activeSection > -1) {
      variant = sections[activeSection].pieVariant;
    }
    setPieVariant(variant);
  }, [activeSection, sections]);

  return (
    <div className="navigation" id="navigation">
      <StartOverButton
        show={activeSection > -1}
        title="start over"
        onClick={() => handleStartOver()} />

      <PieThirds
        shouldPauseHover={activeStory != null}
        onUpClick={() => {handleStartOver()}}
        skinnyHoverColor={activeSection > -1 ? sections[activeSection].navBackground : ''}
        selectionTitle={activeSection > -1 ? sections[activeSection].title : ''}
        isSecondStateActive={activeSection > -1}
        onSectionClick={(index, section) => {if(onSectionClick) onSectionClick(index, section)}}
        onHoverStart={(section) => {if(onHoverStart) onHoverStart(section);}}
        onHoverEnd={() => {if(onHoverEnd) onHoverEnd();}}
        defaultCenterText={centerText}
        sections={sections}
        variant={pieVariant} />

      {/* Second state pies for Desktop */}
      {/* Halves */}
      <PieHalves
        activeStory={activeStory}
        onStoryClick={(story) => {handleStoryClicked(story)}}
        isActive={activeSection === 0}
        section={sections[0]}
      />
      {/* Sixths */}
      <PieSixths
        activeStory={activeStory}
        onStoryClick={(story) => {handleStoryClicked(story)}}
        isActive={activeSection === 1}
        section={sections[1]}
      />
      {/* Sevenths */}
      <PieSevenths
        activeStory={activeStory}
        onStoryClick={(story) => {handleStoryClicked(story)}}
        isActive={activeSection === 2}
        section={sections[2]}
      />
      {/* Hidden SVG wedges used by clip-path in CSS */}
      <Wedges />
    </div>
  )
}

export default RingNavigation;