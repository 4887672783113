import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import Audio from '../../helpers/audio';

const StoryBodyDesktop = ({selectedStoryContent, onNavigateToStory}) => {

  const prevStoryRef = useRef();

  const handleNavigateToStory = () => {
    if(onNavigateToStory) {
      onNavigateToStory();
    }
  }

  useEffect(() => {
    if(selectedStoryContent) {
      prevStoryRef.current = selectedStoryContent;
    }
  }, [selectedStoryContent]);

  if(!selectedStoryContent && !prevStoryRef.current) {
    return (<div />)
  }

  return (
    <div className={`story-content ${selectedStoryContent ? 'visible' : ''}`} id="third-state">
      <div className="story-content--text">
        <p className="eyebrow">{selectedStoryContent ? selectedStoryContent.title : prevStoryRef.current.title}</p>
        <p className="quote">{selectedStoryContent ? selectedStoryContent.storytellingPage.navContent.quote : prevStoryRef.current.storytellingPage.navContent.quote}</p>
        <p className="attribution">{selectedStoryContent ? selectedStoryContent.storytellingPage.navContent.attribution : prevStoryRef.current.storytellingPage.navContent.attribution}</p>
        <p className="description">{selectedStoryContent ? selectedStoryContent.storytellingPage.navContent.description : prevStoryRef.current.storytellingPage.navContent.description}</p>
        <Link
          onClick={() => {handleNavigateToStory()}}
          className="text-cta"
          to={selectedStoryContent ? selectedStoryContent.uri : prevStoryRef.current.uri}>Learn More About the {selectedStoryContent ? selectedStoryContent.title : prevStoryRef.current.title} {selectedStoryContent ? selectedStoryContent.categories.nodes[0].slug : prevStoryRef.current.categories.nodes[0].slug}</Link>
      </div>
      {(selectedStoryContent && selectedStoryContent.storytellingPage.navContent.audioFile) ?
        <Audio src={selectedStoryContent.storytellingPage.navContent.audioFile.localFile.publicURL} color={selectedStoryContent.categories.nodes[0].slug} linePosition="bottom desktop--left" /> :
        <div style={{width: '74px'}} /> // Keeps the layout from shifting if there's no audio
      }
    </div>
  )
}

export default StoryBodyDesktop;