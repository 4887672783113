import React, { useCallback, useEffect, useRef, useState, useLayoutEffect } from "react"
import Fade from "../../helpers/fade"
import StoryBodyMobile from "./story-body-mobile"
import { Transition, TransitionGroup } from "react-transition-group"

const SectionsModalMobile = ({ show, activeStory, onStoryClick, activeSection, customStoryCTAtext, onClose, onNavigateToStory }) => {

  let isDesktop;
  const timeoutId = useRef();
  const scrollPos = useRef(0);

  const timeout = 500
  const getTransitionStyles = {
    entering: {
      opacity: 0,
    },
    entered: {
      transition: `opacity ${timeout}ms ease-out`,
      opacity: 1,
    },
    exiting: {
      transition: `opacity ${timeout}ms ease-out`,
      opacity: 0,
    },
  }

  useEffect(() => {
    scrollPos.current = window.scrollY;
  }, []);

  useLayoutEffect(() => {
    const desktopMatches = window.matchMedia('(max-width: 1299px)')
    if(desktopMatches.addEventListener) {
      desktopMatches.addEventListener('change', 
        (e) => {
          e.matches ? isDesktop = false : isDesktop = true
      });
    } 
    if(show) {
      toggleBodyScroll(!isDesktop);
    } else {
      toggleBodyScroll(true);
    }
  }, [isDesktop])

  useEffect(() => {
    if(show) {
      toggleBodyScroll(window.innerWidth > 1299);
    } else {
      toggleBodyScroll(true);
    }
  }, [show]);

  const toggleBodyScroll = (isEnabled) => {
    if(isEnabled) {
      document.body.classList.remove('modal--open');
      if(window.scrollY === 0) {
        window.scrollTo(0, scrollPos.current);
      }
    } else {
      if(window.scrollY > 0) {
        scrollPos.current = window.scrollY;
      }

      if(timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }

      timeoutId.current = setTimeout(() => {
        if(window.innerWidth <= 1299) {
          document.body.classList.add('modal--open');
        }
      }, 1200);
    }
  }

  const handleClose = () => {
    toggleBodyScroll(true);

    if (onClose) {
      onClose();
    }
  }

  const handleStoryClicked = (story) => {
    if(onStoryClick) {
      onStoryClick(story);
    }
  }

  const handleNavigateToStory = () => {
    toggleBodyScroll(true);

    if(onNavigateToStory) {
      onNavigateToStory();
    }
  }

  return (
    <TransitionGroup component={null}>
      <Transition
        key={activeSection ? activeSection.title : 'empty-section'}
        in={show}
        timeout={timeout}
      >
        {status => (
          activeSection ? <div className={`state-two modal`}
               style={{ ...getTransitionStyles[status],
                 background: `right / cover  no-repeat url(${activeSection.backgroundImage})`,
               }}>
            <div className="blur">
              <button
                className="close-x"
                onClick={() => {handleClose()}}>
                X
              </button>
              <div className="state-two--content">

                <h1 className="h1">{activeSection.title}</h1>
                <p className="p2">{activeSection.description}</p>
                <ul className="navigation--state-two" style={{ background: activeSection.navBackground }}>
                  {activeSection.items.map((item, index, items) => {
                    return (
                      <li key={index}>
                        <button
                          onClick={() => {
                            handleStoryClicked(item.story[0]);
                          }}
                          className="eyebrow eyebrow--small">{item.navItem}</button>
                      </li>)
                  })}
                </ul>

              </div>

              <Fade show={activeStory != null} fadeSpeed="0.5s">
                <StoryBodyMobile
                  onStoryClick={(story) => handleStoryClicked(story)}
                  onBackClick={() => handleStoryClicked(null)}
                  activeSection={activeSection}
                  onNavigateToStory={() => {
                    handleNavigateToStory();
                  }}
                  activeStory={activeStory} />
              </Fade>
            </div>
          </div> : <div className="state-two empty"/>
        )}
      </Transition>
    </TransitionGroup>
  )

}

export default SectionsModalMobile