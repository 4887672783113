import React, { } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ComponentWrapper from "../components/wrapper"
import SecondaryHero from "../modules/secondary-hero"
import QuoteImageOverlap from "../modules/quote-image-overlap";
import ContactForm from "../modules/contact-form";

const TakeAction = ({ data, location }) => {
  const page = data.allWpPage.nodes[0].takeActionPage;

  return (
    <Layout location={location} title={data.site.siteMetadata.siteTitle}>
      <Seo title="Take Action" />

      <div className="take-action-page">
        {page.fullWidthHero && 
        <SecondaryHero
          eyebrow={page.heroInsideGroup.eyebrow}
          headline={page.heroInsideGroup.headline}
          background={page.heroInsideGroup.backgroundImage}
          middleground={page.heroInsideGroup.middlegroundImage}
          foreground={page.heroInsideGroup.foregroundImage}
        />
        }
        {page.takeAction && page.takeAction.map(module => {
          switch(module.__typename) {
            case 'WpPage_Takeactionpage_TakeAction_QuoteImageOverlap' :
            return <QuoteImageOverlap
                      quote={module.quote}
                      attribution={module.attribution}
                      image={module.image}
                      imageAlign={module.imageAlign}
                      eyebrow={module.eyebrow}
                    />
          default :
          return <ComponentWrapper><p className="p3">Our apologies but We could not find that module.</p></ComponentWrapper>

          }
        })}

        <ComponentWrapper>
          <div className="grid-wrapper">
            <div className="content">
              <h5 className="h5">{page.heading}</h5>
              <p className="p3">{page.copy}</p>
            </div>
            <ContactForm />
          </div>
      </ComponentWrapper>

      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query TAquery {
    site {
      siteMetadata {
        title
      }
    }
    allWpPage(filter: {slug: {eq: "take-action"}}) {
      nodes {
        takeActionPage {
          heading
          copy
          fullWidthHero
          heroInsideGroup {
            eyebrow
            headline
            backgroundImage {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                    src    
                  }
                }
              }
            }
            middlegroundImage {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                    src    
                  }
                }
              }
            }
            foregroundImage {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                    src    
                  }
                }
              }
            }
          }
          takeAction {
            __typename
            ... on WpPage_Takeactionpage_TakeAction_QuoteImageOverlap {
              attribution
              eyebrow
              imageAlign
              quote
              image {
                id
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`
export default TakeAction;