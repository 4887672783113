// https://czaplinski.io/blog/super-easy-animation-with-react-hooks/

import React, { useEffect, useState } from "react";

const Fade = ({ show, fadeSpeed, className = null, children, delay}) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    render && (
      <div
        style={{
          animation: `${show ? "fadeIn" : "fadeOut"} ${fadeSpeed}`,
          animationDelay: delay ? delay : '0s',
          animationFillMode: "forwards"
        }}
        onAnimationEnd={onAnimationEnd}
        className={className}
      >
        {children}
      </div>
    )
  );
};

export default Fade;
