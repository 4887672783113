import React, {useState, useEffect} from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ComponentWrapper from "../components/wrapper"

import Accordion from "../modules/accordion"
import SecondaryHero from "../modules/secondary-hero"

import BasicQuote from "../modules/basic-quote"
import CopyImage from "../modules/copy-image"
import HorizontalImageCopy from "../modules/horizontal-image-copy"
import OffsetQuote from "../modules/quote-offset"
import CopyStacked from "../modules/copy-stacked"
import CopySide from "../modules/copy-side"
import QuoteImageOverlap from "../modules/quote-image-overlap"
import QuoteImageSideBySide from "../modules/quote-image-side-by-side"
import HowToHelp from "../modules/how-to-help"
import NextStoryLink from "../modules/next-story-link"
import ThreeUpCards from "../modules/three-up-cards"
import Documents from "../modules/documents"
import DocumentsAccordion from "../modules/documents-accordion"
import TabbedContent from "../modules/tabbed-content"
import PageAnchor from "../modules/page-anchor"
import TableOfContents from "../modules/table-of-contents"

const Pages = ({ data: { page }, location}) => {

  let highestzIndex = page.tertiaryPages.pageContent.length + 1;
  const [docsType, setDocsType] = useState('desktop');

  useEffect( () => {
    const mediaQuery = window.matchMedia('(max-width: 1299px)');
    const windowWidth = window.innerWidth
    if (windowWidth < 1299) {
      setDocsType('mobile')
    }

    if(mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', 
        (e) => {
          e.matches ? setDocsType('mobile') : setDocsType('desktop')
      });
    } else if(mediaQuery.addListener) {
      mediaQuery.addListener(setDocsType('desktop'));
    }

  })

  return (
    <Layout>
      <Seo title={page.seo.title} description={page.seo.metaDesc} />

      {page.tertiaryPages.fullWidthHero && 
        <SecondaryHero
          eyebrow={page.tertiaryPages.heroInsideGroupSeamless.eyebrow}
          headline={page.tertiaryPages.heroInsideGroupSeamless.headline}
          background={page.tertiaryPages.heroInsideGroupSeamless.backgroundImage}
          middleground={page.tertiaryPages.heroInsideGroupSeamless.middlegroundImage}
          foreground={page.tertiaryPages.heroInsideGroupSeamless.foregroundImage}
        />
      }

      {page.tertiaryPages.tableOfContents && 
        <TableOfContents
          reverseZindex={highestzIndex}
          introCopy={page.tertiaryPages.introCopyToc.introCopy}
          hasTooltip={page.tertiaryPages.introCopyToc.addATooltip}
          tooltip={page.tertiaryPages.introCopyToc.tooltipText}
          introCTA={page.tertiaryPages.introCopyToc.introCta}
          tocTitle={page.tertiaryPages.introCopyToc.tableOfContentsTitle}
          modules={page.tertiaryPages.pageContent}
          location={location}
        />
      }

      {page.tertiaryPages.pageContent && 
      page.tertiaryPages.pageContent.map( (module, idx, modules) => {
        const reverseZindex = modules.length-idx;
        switch (module.__typename) {
          case 'WpPage_Tertiarypages_PageContent_Accordion' :
            return <Accordion
                      reverseZindex={reverseZindex}
                      intro={module.introSection}
                      items={module.accordionItems}
                      hasTooltip={module.addATooltip}
                      tooltip={module.tooltipText}
                    />
          case 'WpPage_Tertiarypages_PageContent_AnchorLink' :
            return <PageAnchor
                      title={module.linkTitle}
                    />
          case 'WpPage_Tertiarypages_PageContent_BasicQuote' :
            return <BasicQuote
                      reverseZindex={reverseZindex}
                      quote={module.quote}
                      attribute={module.attribute}
                      quoteSize={module.quoteSize}
                      backgroundImage={module.backgroundImage}
                    />
          case 'WpPage_Tertiarypages_PageContent_CopyImage' :
            return <CopyImage
                      reverseZindex={reverseZindex}
                      pinline={module.pinline}
                      copy={module.copy}
                      hasTooltip={module.addATooltip}
                      tooltip={module.tooltipText}
                      image={module.image}
                      imageAlign={module.imageAlign}
                      imageSize={module.imageSize}
                      ctaButton={module.ctaButton}
                      storyColor={"white"}
                  />
          case 'WpPage_Tertiarypages_PageContent_HorizontalImageCopy' :
            return <HorizontalImageCopy
                      reverseZindex={reverseZindex}
                      copy={module.copy}
                      hasTooltip={module.addATooltip}
                      tooltip={module.tooltipText}
                      image={module.image}
                      imageSize={module.imageSize}
                      cta={module.cta}
                      storyColor={"white"}
                  />
          case 'WpPage_Tertiarypages_PageContent_OffsetQuote' :
            return <OffsetQuote
                        reverseZindex={reverseZindex}
                      quote={module.quote}
                      attribution={module.attribution}
                      audio={module.audioFile}
                      eyebrow={module.eyebrow}
                      quoteAlign={module.quoteAlign}
                      quoteSize={module.quoteSize}
                      storyColor={"white"}
                  />
          case 'WpPage_Tertiarypages_PageContent_CopyStacked' :
            return <CopyStacked
                    reverseZindex={reverseZindex}
                    bodyCopy={module.bodyCopy}
                    hasTooltip={module.addATooltip}
                    tooltip={module.tooltipText}
                    ctaButton={module.ctaButton}
                    storyColor={"white"}
                  />
          case 'WpPage_Tertiarypages_PageContent_CopySideBySide' :
            return <CopySide
                    reverseZindex={reverseZindex}
                    column2={module.bodyCopy}
                    column1={module.headline}
                    ctaButtonColumn1={module.ctaButtonColumn1}
                    ctaButton={module.ctaButton}
                    storyColor={"white"}
                    pinlineColumn1={module.pinlineColumn1}
                    pinlineColumn2={module.pinlineColumn2}
                    hasTooltip1={module.addATooltipToColumn1}
                    tooltip1={module.tooltipTextColumn1}
                    hasTooltip2={module.addATooltipToColumn2}
                    tooltip2={module.tooltipTextColumn2}
                  />
          case 'WpPage_Tertiarypages_PageContent_QuoteImageOverlap' :
            return <QuoteImageOverlap
                    reverseZindex={reverseZindex}
                    attribution={module.attribution}
                    eyebrow={module.eyebrow}
                    image={module.image}
                    imageAlign={module.imageAlign}
                    quote={module.quote}
                  />
          case 'WpPage_Tertiarypages_PageContent_QuoteImageSideBySide' :
            return <QuoteImageSideBySide
                    reverseZindex={reverseZindex}
                    attribution={module.attribution}
                    eyebrow={module.eyebrow}
                    image={module.image}
                    imageAlign={module.imageAlign}
                    quote={module.quote}
                  />
          case 'WpPage_Tertiarypages_PageContent_CtaBox' :
            return <HowToHelp
                    reverseZindex={reverseZindex}
                    bodyCopy={module.bodyCopy}
                    hasTooltip={module.addATooltip}
                    tooltip={module.tooltipText}
                    ctaButton={module.ctaButton}
                    headline={module.headline}
                    storyColor={"white"}
                  />
          case 'WpPage_Tertiarypages_PageContent_NextStory' :
            return <NextStoryLink
                    reverseZindex={reverseZindex}
                    eyebrow={module.eyebrow}
                    nextLink={module.nextLink}
                  />
          case 'WpPage_Tertiarypages_PageContent_3UpEndCards' :
            return <ThreeUpCards
            reverseZindex={reverseZindex}
            copy1={module.bodyCopy1}
            copy2={module.bodyCopy2}
            copy3={module.bodyCopy3}
            cta1={module.ctaButton1}
            cta2={module.ctaButton2}
            cta3={module.ctaButton3}
            header1={module.header1}
            header2={module.header2}
            header3={module.header3}
                  />
          case "WpPage_Tertiarypages_PageContent_Resources" :
            // This block is a repeater.
            // Inside there is another repeater that has a flexible content field inside of that.
            return (

              <ComponentWrapper zIndex={reverseZindex}>
                {module.documentsBlock.map( (block, index) =>  {
                  
                  if (docsType === 'desktop') {
                    return <Documents 
                             headline={block.header}
                             copy={block.copy}
                             documents={block.documents}
                             key={index} />
                  } else if (docsType === 'mobile') {
                    return <DocumentsAccordion 
                            headline={block.header}
                            copy={block.copy}
                            documents={block.documents}
                            key={index}
                            index={index} />
                  }
                })}
              </ComponentWrapper>
            )
          case "WpPage_Tertiarypages_PageContent_TabbedContent" :
            // This block is a repeater.
            return (
              <TabbedContent 
                tabs={module.tabContent}
              />
            )
  

          default :
            return <ComponentWrapper><p className="p3">Our apologies but We could not find that module.</p></ComponentWrapper>
        }
      })}
    </Layout>
  )
}

export default Pages;


export const pageQuery = graphql`
  query PageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current page by id
    page: wpPage(id: { eq: $id }) {
      id
      title
      slug
      seo {
        metaDesc
        title
      }
      tertiaryPages {
        fullWidthHero
        heroInsideGroupSeamless {
          eyebrow
          headline
          backgroundImage {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                  src    
                }
              }
            }
          }
          middlegroundImage {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                  src    
                }
              }
            }
          }
          foregroundImage {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                  src    
                }
              }
            }
          }
        }
        tableOfContents
        introCopyToc {
          introCopy
          addATooltip
          tooltipText
          introCta {
            title
            url
          }
          tableOfContentsTitle
        }
        pageContent {
          __typename
          ... on WpPage_Tertiarypages_PageContent_BasicQuote {
            attribute
            backgroundImage {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                    src    
                  }
                }
              }
            }
            quote
            quoteSize
          }
          ... on WpPage_Tertiarypages_PageContent_AnchorLink {
            linkTitle
          }
          ... on WpPage_Tertiarypages_PageContent_Accordion {
            introSection
            addATooltip
            tooltipText
            accordionItems {
              header
              content
              addATooltip
              tooltipText
            }
          }
          ... on WpPage_Tertiarypages_PageContent_CopyImage {
            pinline
            copy
            addATooltip
            tooltipText
            ctaButton {
              title
              url
              target
            }
            imageAlign
            imageSize
            image {
              altText
              localFile { publicURL }
            }
          }
          ... on WpPage_Tertiarypages_PageContent_HorizontalImageCopy {
            copy
            addATooltip
            tooltipText
            cta {
              title
              url
              target
            }
            imageSize
            image {
              altText
              localFile { publicURL }
            }
          }
          ... on WpPage_Tertiarypages_PageContent_OffsetQuote {
            attribution
            audioFile {
              caption
              localFile {
                publicURL
              }
            }
            eyebrow
            quote
            quoteAlign
            quoteSize
          }
          ... on WpPage_Tertiarypages_PageContent_CopyStacked {
            bodyCopy
            addATooltip
            tooltipText
            ctaButton {
              title
              url
            }
          }
          ... on WpPage_Tertiarypages_PageContent_CopySideBySide {
            bodyCopy
            addATooltipToColumn1
            tooltipTextColumn1
            headline
            addATooltipToColumn2
            tooltipTextColumn2
            ctaButton {
              title
              url
            }
            ctaButtonColumn1 {
              title
              url
              target
            }
            pinlineColumn1
            pinlineColumn2
          }
          ... on WpPage_Tertiarypages_PageContent_QuoteImageOverlap {
            attribution
            eyebrow
            image {
              altText
              localFile {
                publicURL
              }
            }
            imageAlign
            quote
          }
          ... on WpPage_Tertiarypages_PageContent_CtaBox {
            bodyCopy
            addATooltip
            tooltipText
            ctaButton {
              title
              url
            }
            headline
          }
          ... on WpPage_Tertiarypages_PageContent_NextStory {
            eyebrow
            nextLink {
              title
              url
            }
          }
          ... on WpPage_Tertiarypages_PageContent_3UpEndCards {
            bodyCopy1
            bodyCopy2
            bodyCopy3
            ctaButton1 {
              title
              url
            }
            ctaButton2 {
              title
              url
            }
            ctaButton3 {
              title
              url
            }
            header1
            header2
            header3
          }
          ... on WpPage_Tertiarypages_PageContent_QuoteImageSideBySide {
            attribution
            image {
              altText
              localFile {
                publicURL
              }
            }
            imageAlign
            quote
          }
          ... on WpPage_Tertiarypages_PageContent_Resources {
            documentsBlock {
              header
              copy
              documents {
                subhead
                document {
                  __typename
                  ... on WpPage_Tertiarypages_PageContent_Resources_documentsBlock_documents_Document_File {
                    fileName
                    download {
                      mimeType
                      localFile {
                        publicURL
                      }
                    }
                  }
                  ... on WpPage_Tertiarypages_PageContent_Resources_documentsBlock_documents_Document_Link {
                    link {
                      target
                      title
                      url
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Tertiarypages_PageContent_TabbedContent {
            tabContent {
              tabLabel
              introCopy
              button {
                ... on WpPage_Tertiarypages_PageContent_TabbedContent_tabContent_Button_Link {
                  link {
                    target
                    title
                    url
                  }
                }
                ... on WpPage_Tertiarypages_PageContent_TabbedContent_tabContent_Button_File {
                  buttonLabel
                  fieldGroupName
                  file {
                    mediaItemUrl
                    localFile {
                      publicURL
                    }
                  }
                }
              }
              contentBlock {
                sectionalHeader
                sectionalContent {
                  copy
                  header
                }
                quote
                quoteSize
                attribute
              }
            }
          }
        }
      }
    }
  }
`
