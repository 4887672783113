import React from "react"
import PageNotFoundImage from "../../static/page-not-found-404.png"

const PageNotFound = ({ quote, attribution, image, imageAlign, eyebrow }) => {
    return (
        <section>
            {
                // <div className={"quote-overlap-image " + (imageAlign === "align_left" ? 'quote-overlap-image--left' : 'quote-overlap-image--right')}>
                <div className="page-not-found">
                    <div className="container">
                        <div className="text">
                            <div className="eyebrow">404: WE LOST YOU{eyebrow}</div>
                            <h2>The page you’re looking for doesn’t seem to exist. Check out the links below to continue your journey through the Listening Sessions site.</h2>
                        </div>
                        <div className="image">
                            <img src={PageNotFoundImage} 
                            // alt={image.altText ? image.altText : ''} 
                            />
                        </div>
                    </div>
                </div>
            }
        </section>
    )
}

export default PageNotFound