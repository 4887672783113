import React, { useEffect, useState } from "react"
import { CSSTransition, ReplaceTransition, SwitchTransition, TransitionGroup } from "react-transition-group"
import SmoothText from "./smooth-text"

/**
 * Creates a navigation pie chart.
 * @param {string} variant One of the following: 'halves', 'thirds', 'sixths', or 'sevenths'
 * @param {Array} sections The array of sections from the parent component
 * @returns {JSX.Element}
 * @constructor
 */
const PieThirds = ({ shouldPauseHover, onUpClick, skinnyHoverColor, selectionTitle, isSecondStateActive, defaultCenterText, variant, sections, onHoverStart, onHoverEnd, onSectionClick }) => {

  const [centerText, setCenterText] = useState(defaultCenterText);
  const [showCta, setShowCta] = useState(true);

  const handleHoverStart = (section) => {
    setCenterText(section.description);
    setShowCta(false);

    if(onHoverStart) {
      onHoverStart(section);
    }
  }

  const handleHoverEnd = () => {
    setCenterText(defaultCenterText);
    setShowCta(true);

    if(onHoverEnd) {
      onHoverEnd();
    }
  }

  const handleUpClicked = () => {
    if(isSecondStateActive && !shouldPauseHover && onUpClick) {
      onUpClick();
    }
  }

  return (
    <ul
      style={{
        pointerEvents: shouldPauseHover ? 'none' : 'auto'
      }}
      onClick={() => handleUpClicked()}
      className={`pie ${isSecondStateActive ? 'second-state-active' : ''}`}
      id="pie-first-state">
      <li
          className={`pie--wedge third third--top ${isSecondStateActive ? 'selected' : ''}`}
          id="third--journey">
        <button
          onClick={() => {if(onSectionClick) onSectionClick(0, sections[0])}}
          onMouseEnter={() => handleHoverStart(sections[0])}
          onMouseLeave={() => handleHoverEnd()}
          onMouseUp={e => e.preventDefault()}
          id="button-journey">
            <span className="show">
              {/* SVG curved text path tutorial: https://css-tricks.com/snippets/svg/curved-text-along-path/
                -- used for mobile only */}
              <svg width="181" height="121" viewBox="0 0 181 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="semi-circle-1"
                      d="M90.5 7.62939e-06C140.482 7.62939e-06 181 40.5182 181 90.5L0 90.5C0 40.5182 40.5182 7.62939e-06 90.5 7.62939e-06Z"
                      fill="transparent" />
                <text width="181">
                  <textPath xlinkHref="#semi-circle-1">
                    {isSecondStateActive ? selectionTitle : sections[0].title}
                  </textPath>
                </text>
              </svg>
              {isSecondStateActive ? selectionTitle : sections[0].title}
            </span>
        </button>
      </li>
      <div className="third--border third--border__bottom"></div>

      <li className="pie--wedge third third--left" id="third--issues">
        <button
          onClick={() => {if(onSectionClick) onSectionClick(2, sections[2])}}
          onMouseEnter={() => handleHoverStart(sections[2])}
          onMouseLeave={() => handleHoverEnd()}
          id="button-issues">
                  <span className="show">
                    <svg width="181" height="91" viewBox="0 0 181 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path id="semi-circle-2" d="M90.5 0C40.5182 0 0 40.5182 0 90.5H181C181 40.5182 140.482 0 90.5 0Z"
                            fill="transparent" />
                      <text width="181">
                        <textPath xlinkHref="#semi-circle-2">
                          {sections[2].title}
                          </textPath>
                      </text>
                    </svg>
                    {sections[2].title}
                  </span>
        </button>
      </li>
      <div className="third--border third--border__left"></div>

      <li className="pie--wedge third third--right" id="third--lenses">
        <button
          onClick={() => {if(onSectionClick) onSectionClick(1, sections[1])}}
          onMouseEnter={() => handleHoverStart(sections[1])}
          onMouseLeave={() => handleHoverEnd()}
          id="button-lenses">
                  <span className="show">
                    <svg width="181" height="91" viewBox="0 0 181 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path id="semi-circle-3" d="M90.5 0C40.5182 0 0 40.5182 0 90.5H181C181 40.5182 140.482 0 90.5 0Z"
                            fill="transparent" />
                      <text width="181">
                        <textPath xlinkHref="#semi-circle-3">
                          {sections[1].title}
                          </textPath>
                      </text>
                    </svg>
                    {sections[1].title}
                  </span>
        </button>
      </li>
      <div className="third--border third--border__right"></div>
        <div className="center-circle">
          <div className="text mobile">Tap to explore</div>
          <div className="text desktop">
            <SmoothText name="thirds" text={centerText} showCta={showCta}/>
          </div>
        </div>
    </ul>
  )
}

export default PieThirds;