import React from "react"
import { Link } from "gatsby"
import ComponentWrapper from "../components/wrapper"

const ThreeUpCards = ({header1, copy1, cta1, header2, copy2, cta2, header3, copy3, cta3, isFullWidth}) => {
    return (
        
        <section> 
            {/* show divider line only when module above has no fullwidth component */}
        <div className={"three-up-cards " + (isFullWidth === true ? 'three-up-cards--under-fullwidth-module' : ' ')}>
                <div className="container">      
                    <div className="left">
                        <h5>{header1}</h5>
                        <p className="p3">{copy1}</p>
                        <Link to={cta1.url} className="text-cta">{cta1.title}</Link>
                    </div>

                    <div className="middle">
                    <h5>{header2}</h5>
                        <p className="p3">{copy2}</p>
                        <Link to={cta2.url} className="text-cta">{cta2.title}</Link>
                    </div>

                    <div className="right">
                    <h5>{header3}</h5>
                        <p className="p3">{copy3}</p>
                        <Link to={cta3.url} className="text-cta">{cta3.title}</Link>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default ThreeUpCards