import React from "react"

const Layout = ({ isHomePage, children }) => {

  return (
    
    <div className={"global-wrapper " + (isHomePage === true ? 'homepage' : ' ')} data-is-root-path={isHomePage}>
      <main>
        {children}
      </main>
    </div>
  )
}

export default Layout
