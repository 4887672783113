import React, { useState, useEffect, useRef } from "react";
import ComponentWrapper from "../components/wrapper";
import { Link } from "gatsby"
import TabbedAccordion from "./tabbed-accordion";

function TabbedContent({tabs}) {
  const [toggleState, setToggleState] = useState(0);
  const [mediaSize, setMediaSize] = useState('desktop');


  const toggleTab = (index) => {
      setToggleState(index);
  };

  useEffect( () => {
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    const windowWidth = window.innerWidth
    if (windowWidth < 767) {
      setMediaSize('mobile')
    }

    if(mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', 
        (e) => {
          e.matches ? setMediaSize('mobile') : setMediaSize('desktop')
      });
    } else if(mediaQuery.addListener) {
      mediaQuery.addListener(setMediaSize('desktop'));
    }
  })

  if (mediaSize === 'mobile') {
    return(
      <ComponentWrapper>
        <div className="tab-container">
          <div className="tabs">
            {tabs.map((tab, index) => {
              return (
                <TabbedAccordion key={index} index={index} tab={tab} />
              )
            })}
          </div>
        </div>
      </ComponentWrapper>

    )
  } else if (mediaSize === 'desktop') {
    return (
      <ComponentWrapper>
        <div className="tab-container">
          <div className="tabs">
            {tabs.map((tab, index) => {
              return (
                <button
                  key={index}
                  className={`eyebrow eyebrow--big tab ${toggleState === index ? "active-tabs" : ""}`}
                  onClick={() => toggleTab(index)}
                >
                  {tab.tabLabel}
                </button>
              )
            })}
          </div>
  
          <div className="content-tabs">
            {tabs.map((tab, index) => {
              return (
                <div
                key={index}
                className={`content ${toggleState === index ? "active-content" : ""}`}
                >
                  {tab.introCopy && 
                    <div className="tab-content--intro">
                      <p className="p2 sans">
                        {tab.introCopy}
                      </p>
                      {tab.button && tab.button.map( (introButton) => {
                        switch (introButton.fieldGroupName) {
                          case 'Page_Tertiarypages_PageContent_TabbedContent_tabContent_Button_Link' :
                            return <Link to={introButton.url} target={introButton.target} className={`button`}>{introButton.title}</Link>
                          case 'Page_Tertiarypages_PageContent_TabbedContent_tabContent_Button_File' :
                            return <a href={introButton.file.localFile.publicURL} target="_blank" className="button" rel="noreferrer" download>{introButton.buttonLabel}</a>
                        }
                      })}
                      
                    </div>
                  }

                  <div className="tab-content--sectionals">
                    {tab.contentBlock.map( (block, blockNum) => {
                      return (
                        <div className="tab-content--sectional" key={blockNum}>
                          <h2 className="h2 sans">{block.sectionalHeader}</h2>
                          <div className="three-up-cards">
                            <div className="container"> 
                              {block.sectionalContent.map( (section, index) => {
      
                                return (
                                  <div className={`tabbed-three-up tabbed-three-up-${index}`} key={index}>
                                    <h5 className="eyebrow eyebrow--big">{section.header}</h5>
                                    <p className="p3">{section.copy}</p>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                          {block.quote && 
                            <div className={`tab-content--quote basic-quote basic-quote--${block.quoteSize}`}>
                              <h2 className={block.quoteSize}>{block.quote}</h2>
                              {block.attribute &&
                                <div className="attribution">—{block.attribute}</div>
                              }
                            </div>
                          }
                        </div>
                      )
                    })}
                  </div>  {/* end .tab-content--sectionals */}

                </div>  // end .content
              )  
            })}
          </div> {/* .content-tabs */}
        </div>  {/* .tab-container */}
  
      </ComponentWrapper>
    );
  }

}

export default TabbedContent;