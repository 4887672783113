import React, {} from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Hero from "../modules/hero"
import ThreeUpCards from "../modules/three-up-cards"
import RefugeeExperience from "../components/refugee-experience"


const HomePageTemplate = ({data}) => {
  const seo = data.wpPage.seo;
  const page = data.wpPage.homePage;
  // const imageBackground = data.wpPage.homePage.hero.heroBackgroundImage.localFile.childImageSharp.fluid;
  const imageMiddleground = data.wpPage.homePage.hero.heroMiddlegroundImage.localFile.childImageSharp.fluid;
  const imageForeground = data.wpPage.homePage.hero.heroForegroundImage.localFile.childImageSharp.fluid;

  return (
    <Layout isHomePage={true}>
      <Seo title={seo.title} description={seo.metaDesc} />
      <Hero 
        // background={imageBackground}
        middleground={imageMiddleground}
        foreground={imageForeground}
        headlineSize={page.hero.heroHeadlineSize}
        quote={page.hero.heroQuote}
        attribution={page.hero.heroAttribution}
        audio={page.hero.heroAudioClip}
       />
       {/* CopyImage slated for permanent removal */}
      {/* <CopyImage
        pinline={page.copyImage.copyImagePinline}
        copy={page.copyImage.copyImageCopy}
        headline={page.copyImage.copyImageHeadline}
        image={page.copyImage.copyImageImage}
        imageSize={page.copyImage.copyImageImageSize}
        imageAlign={page.copyImage.copyImageImageAlign}
        ctaButton={page.copyImage.copyImageCtaButton}
      /> */}
      <RefugeeExperience />

      <ThreeUpCards
        copy1={page.threeUpCards.threeUpCardsBodyCopy1}
        header1={page.threeUpCards.threeUpCardsHeader1}
        cta1={page.threeUpCards.threeUpCardsCtaButton1}
        copy2={page.threeUpCards.threeUpCardsBodyCopy2}
        header2={page.threeUpCards.threeUpCardsHeader2}
        cta2={page.threeUpCards.threeUpCardsCtaButton2}
        copy3={page.threeUpCards.threeUpCardsBodyCopy3}
        header3={page.threeUpCards.threeUpCardsHeader3}
        cta3={page.threeUpCards.threeUpCardsCtaButton3}
      />
    </Layout>
  )   
}


export const query = graphql`
{
  wpPage(uri: {eq: "/"}) {
    seo {
      metaDesc
      title
    }
    homePage {
      hero {
        heroHeadlineSize
        heroQuote
        heroAttribution
        heroAudioClip {
          localFile {
						publicURL
          }
        }
        heroMiddlegroundImage {
          id
          localFile {
            childImageSharp {
              fluid {
                base64
                srcWebp
                srcSetWebp
                originalImg
                originalName
                src
                srcSet
              }
              gatsbyImageData
            }
          }
        }
        heroForegroundImage {
          id
          localFile {
            childImageSharp {
              fluid {
                base64
                srcWebp
                srcSetWebp
                originalImg
                originalName
                src
                srcSet
              }
              gatsbyImageData
            }
          }
        }
      }
      threeUpCards {
        threeUpCardsHeader1
        threeUpCardsHeader2
        threeUpCardsHeader3
        threeUpCardsBodyCopy1
        threeUpCardsBodyCopy2
        threeUpCardsBodyCopy3
        threeUpCardsCtaButton1 {
          target
          title
          url
        }
        threeUpCardsCtaButton2 {
          target
          title
          url
        }
        threeUpCardsCtaButton3 {
          target
          title
          url
        }
      }
    }
  }
}
`

export default HomePageTemplate;