import React, { useEffect } from "react"

const ContactForm = () => {


  const formSubmissionHandler = (event) => {
    event.preventDefault();
  
    const formElement = event.target,
      { action, method } = formElement,
      body = new FormData(formElement);
  
    fetch(action, {
      method,
      body
    })
      .then((response) => response.json())
      .then((response) => normalizeContactForm7Response(response))
      .then((response) => {
        // Determine if the submission is not valid
        if (!response.isSuccess) {
          // Handle the case when there are validation errors
          document.querySelectorAll('.form-error').forEach( error => {
            error.classList.remove('error');
          })
          for (const error in response.validationError) {
            document.querySelector(`.form-${error}`).classList.add('error');
            document.querySelector(`[name=${error}]`).classList.add('error');
          }
        } else {
          // Handle success
          document.querySelector('.contact-form').classList.add('success');
          document.querySelector('.success-box').classList.add('success');
        }
      })
      .catch((error) => {
        document.querySelector('.server-error').classList.add('success');
      });
  };

  const normalizeContactForm7Response = (response) => {
    // The other possible statuses are different kind of errors
    const isSuccess = response.status === 'mail_sent';
    // A message is provided for all statuses
    const message = response.message;
    const validationError = isSuccess ? {} :
      // We transform an array of objects into an object
      Object.fromEntries(
        response.invalid_fields.map((error) => {
          // Extracts the part after "cf7-form-control-wrap"
          const key = /cf7[-a-z]*.(.*)/.exec(error.into)[1];
  
          return [key, error.message];
        })
      );
  
    return {
      isSuccess,
      message,
      validationError,
    };
  };
  
  useEffect(() => {
    const formElement = document.querySelector("form");
    
    formElement.addEventListener("submit", formSubmissionHandler);
  })

  return (
    <div className="contact-form-wrapper">
      <form action={`${process.env.CONTACT_FORM_ENDPOINT}`} method="post" noValidate className="contact-form">
        <label htmlFor="full-name"> Full name</label>
          <input id="full-name" type="text" name="full-name" placeholder="Full name" required maxlength="100" />
          <p className="p4 form-full-name form-error">Please enter your name</p>

        <label htmlFor="email"> Email Address</label>
            <input id="email" type="email" name='email' placeholder="Email address" required maxlength="100" />
            <p className="p4 form-email form-error">Please enter a valid email address</p>

        <label htmlFor="organization"> Organization/Affiliation (optional)</label>
            <input id="organization" type="text" name='organization' placeholder="Organization/Affiliation (optional)" maxlength="100" />
            <p className="p4 form-organization form-error">Please enter a valid organization</p>

        <label htmlFor="message"> Message</label>
            <textarea id="message" name="message" placeholder="Message" required/>
            <p className="p4 form-message form-error">Please enter a message</p>
        <button type="submit" className="button">Send Message</button>
      </form>

      <div className="success-box">
        <h3 className="h3">Thank you for contacting us.</h3>
        <p className="p2">Nullam quis risus eget urna mollis ornare vel eu leo. Sed posuere consectetur est at lobortis.</p>
      </div>

      <div className="server-error">
        <p className="p2">We're sorry, there was an error with the server. Please refresh the page and try again.</p>
      </div>

    </div>
  )
}

export default ContactForm;