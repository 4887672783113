import React from "react"

const StartOverButton = ({show, title, onClick}) => {

  const handleClicked = () => {
    if(onClick) {
      onClick();
    }
  }

  return (
    <button
      className={`start-over ${show ? 'show' : ''}`}
      onClick={() => handleClicked()}
      id="start-over">
      <span className="reverse-line"></span>
      <span className="reverse-arrow"></span>
      {title}
    </button>
  );
}

export default StartOverButton;