import React, {useEffect} from "react"
import BackgroundImage from 'gatsby-background-image'
import Rellax from "rellax";
import Audio from '../helpers/audio';



const Hero = ({
  middleground, foreground, headlineSize, quote, attribution, audio
}) => {

  useEffect(() => {
    const rellax = new Rellax('.rellax');
  })

  const handleScroll = event => {
    if (document.getElementById('refugee-experience')) {
      document.getElementById('refugee-experience').scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }

  return (
    <section className="hero" role="banner" aria-label="Hero Section">
      <div className="hero--background">
        { /* Leaving background image code for now pending permanent removal */ }
        {/* <BackgroundImage
          Tag="div"
          className="background background--background rellax"
          fluid={background}
          style={{'position':'absolute'}}
          data-rellax-speed="0"
        /> */}
        <BackgroundImage
          Tag="div"
          className="background background--middleground rellax"
          fluid={middleground}
          style={{'position':'absolute', 'backgroundPosition': '58% 50%'}}
          data-rellax-speed="4"
        />
        <BackgroundImage
          Tag="div"
          className="background background--foreground rellax"
          fluid={foreground}
          style={{'position':'absolute', 'backgroundPosition': '66% 50%'}}
          data-rellax-speed="-3"
        />
      </div>

      <div className="hero--text">
        { audio &&
          <Audio src={audio.localFile.publicURL} color="chili" linePosition="bottom desktop--right" />
        }
        <h1 className={`quote ${headlineSize}`}>{quote}</h1>
        <p className="attribution">{attribution}</p>
      </div>
      <div className="hero--scroll" onClick={handleScroll}>
        <p className="cta">SCROLL DOWN</p>
        <div className="down-arrow">
          <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.60938 0.916016C7.39453 0.916016 7.23828 1.07227 7.23828 1.27734V15.7793L7.27734 17.8594L5.04102 15.5352L1.13477 11.6387C1.06641 11.5703 0.958984 11.541 0.851562 11.541C0.65625 11.541 0.490234 11.6973 0.490234 11.9121C0.490234 12.0195 0.529297 12.1074 0.607422 12.1855L7.33594 18.875C7.41406 18.9531 7.50195 18.9922 7.60938 18.9922C7.7168 18.9922 7.80469 18.9531 7.88281 18.875L14.6113 12.1855C14.6895 12.1074 14.7285 12.0195 14.7285 11.9121C14.7285 11.6973 14.5625 11.541 14.3672 11.541C14.2598 11.541 14.1523 11.5703 14.084 11.6387L10.1777 15.5352L7.94141 17.8594L7.98047 15.7793V1.27734C7.98047 1.07227 7.82422 0.916016 7.60938 0.916016Z" fill="white"/>
          </svg>
        </div>
      </div>
    </section>
  )

}

export default Hero;