import React, {useRef}  from 'react';
import {Link} from "gatsby";
import { CSSTransition } from 'react-transition-group';

const NavDropDown = ({ index, item, isSubNavOpen, toggleSubNav, toggleNav}) => {

  return (
    <li className={`nav__item ${isSubNavOpen ? 'expanded': ''}`} key={index}>
      <div className="nav__item-title" onClick={() => toggleSubNav(index)}>
        <span>{item.headerNavItem.title}</span>
        <span className="nav-plus">
          <span className={`nav-plus__lines ${isSubNavOpen ? 'expanded': ''}`}></span>
          <span className="nav-plus__lines"></span>
        </span>
        <span className="nav-arrow">
          <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.98558 0.378871L5.64111 4.62151L1.29665 0.378871" stroke="white"/>
          </svg>
        </span>
      </div>
      <CSSTransition in={isSubNavOpen} timeout={1000} classNames={'subnav-transition'} unmountOnExit>
        <ul className="sub-nav">
          {item.subNavItems.map( (subItem, subIndex) => {
            return (
              <li className="sub-nav__item" key={subIndex}>
                <Link
                  to={subItem.subNavItem.url}
                  onClick={() => {
                    if ( toggleNav ) {
                      toggleNav();
                    }
                    toggleSubNav(-1);
                  }}>
                    {subItem.subNavItem.title}
                </Link>
              </li>
            )
          })}
        </ul>
      </CSSTransition>
    </li>
  );
};

export default NavDropDown;