import React, { useEffect, useRef, useState } from "react"

const AccordionSection = ({title, onClick, onItemSelect, items, active, activeStory, underlineActive = false}) => {

  const [isActive, setActive] = useState(active);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  const shallowCompare = (obj1, obj2) => {
    return Object.keys(obj1).length === Object.keys(obj2).length &&
      obj1.id === obj2.id && obj1.uri && obj2.uri && obj1.title === obj2.title;
  }

  const handleAccordionClicked = () => {
    const newActive = !isActive;
    setActive(newActive);

    if(onClick) {
      onClick(newActive);
    }
  }

  const handleItemSelected = (story) => {
    if(onItemSelect) {
      onItemSelect(story);
    }
  }

  useEffect(() => {
    setContentHeight(contentRef.current.scrollHeight);
    
    window.addEventListener('resize', () => setContentHeight(contentRef.current.scrollHeight))

    return( () => {
      window.removeEventListener('resize', () => setContentHeight)
    })
  }, [contentRef]);

  /**
   * Watch the active prop for a change change
   * from the parent.
   */
  useEffect(() => {
    setActive(active);
  }, [active]);

  return (
    <div className="accordion__section">
      <button className={`accordion ${isActive ? 'active' : ''}`} onClick={() => handleAccordionClicked()}>
        <p className="accordion__title">{title}</p>
      </button>
      <div className={`accordion__content`} ref={contentRef} style={{maxHeight: isActive ? `${contentHeight}px` : `0px`}}>
        {
          items.map((item, index) => {
            return (
              <button
                key={index}
                dangerouslySetInnerHTML={{ __html: item.navItem }}
                slug={item.story[0].uri}
                onClick={() => handleItemSelected(item.story[0])}
                className={`accordion__text ${activeStory && shallowCompare(item.story[0], activeStory) ? 'underline' : ''}`} />
            )
          })
        }
      </div>
    </div>
  )
}

export default AccordionSection;