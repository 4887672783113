import React, { useEffect } from "react"
import BackgroundImage from 'gatsby-background-image'
import Rellax from "rellax";


const SecondaryHero = ({ eyebrow, headline, background, middleground, foreground }) => {

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    let vw = window.innerWidth;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      // Set view height
      let vhResize = window.innerHeight * 0.01;
      let vwResize = window.innerWidth;
      if (vw !== vwResize && vh !== vhResize) {
        document.documentElement.style.setProperty('--vh', `${vhResize}px`);
      }
    })
    const rellax = new Rellax('.rellax');
  })

  return (
    <div className="secondary-hero">
      <div className="secondary-hero--background">
        {background && 
          <BackgroundImage
            Tag="div"
            className="background background--background rellax"
            fluid={background.localFile.childImageSharp.fluid}
            style={{ 'position': 'absolute' }}
            data-rellax-speed="0"
          />
        }
        <BackgroundImage
          Tag="div"
          className="background background--middleground rellax"
          fluid={middleground.localFile.childImageSharp.fluid}
          style={{ 'position': 'absolute', 'backgroundPosition': '58% 50%' }}
          data-rellax-speed="4"
        />
        <BackgroundImage
          Tag="div"
          className="background background--foreground rellax"
          fluid={foreground.localFile.childImageSharp.fluid}
          style={{ 'position': 'absolute', 'backgroundPosition': '66% 50%' }}
          data-rellax-speed="-3"
        />
      </div>
      <div className="container">
        <div className="container-item">
          <div className="eyebrow">{eyebrow}</div>
          <h1>{headline}</h1>
        </div>
      </div>
    </div>
  )

}

export default SecondaryHero;