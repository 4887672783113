import React from "react"
import ComponentWrapper from "../components/wrapper"
import ScrollAnimation from "../helpers/scrollAnimations"

const QuoteImageOverlap = ({reverseZindex, quote, attribution, image, imageAlign, eyebrow }) => {
    return (
        <ComponentWrapper>
            <ScrollAnimation zIndex={reverseZindex}>
                <div className={`quote-image-overlap quote-image-overlap--${imageAlign}`}>
                    <div className="container">
                        <div className="text">
                            {eyebrow &&
                                <div className="eyebrow">{eyebrow}</div>
                            }
                            <h2>{quote}</h2>
                            {attribution &&
                                <div className="attribution">{attribution}</div>
                            }
                        </div>
                        <div className="image">
                            <img src={image.localFile.publicURL} alt={image.altText ? image.altText : ''} />
                        </div>
                    </div>
                </div>
            </ScrollAnimation>
        </ComponentWrapper>
    )
}

export default QuoteImageOverlap