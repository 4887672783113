import React, {useEffect, useRef, useState} from "react"
import DocumentsAccordionInner from './documents-accordion-inner'

const DocumentsAccordion = ({headline, copy, documents, index}) => {

  const refContentTopLevel = useRef(null);
  const [isActive, setIsActive] = useState(index === 0 ? true : false);
  const [heightTopLevel, setHeightTopLevel] = useState('0px');

  const handleAccordionClick = () => {
    if (!isActive) {
      setIsActive(true)
      setHeightTopLevel(`${refContentTopLevel.current.scrollHeight}px`)
    } else if (isActive) {
      setIsActive(false)
      setHeightTopLevel(`0px`)
    }
  }
  useEffect( () => {
    if(!documents[0].subhead) {
      setHeightTopLevel(index === 0 ? `${refContentTopLevel.current.scrollHeight}px` : '0px')
    }
  }, [])

  return (
    <div className="documents-wrapper accordion__section">
      <button
        className={`h5 accordion ${isActive ? 'show' : ''}`}
        onClick={handleAccordionClick}
        >
        <span className="text">{headline}</span>
        <span className={`plus ${isActive ? '' : 'show'}`}></span>
        <span className={`minus ${isActive ? 'show' : ''}`}></span>
      </button>
      <div
        className="accordion__content"
        ref={refContentTopLevel}
        style={{ maxHeight: `${heightTopLevel}` }}
      >
        { copy &&
          <p className="p2 sans">{copy}</p>
        }

        <div className={`documents`}>
          <div className="document">
            {documents.map( (document, SLindex, array) => {
              return <DocumentsAccordionInner
                        document={document}
                        copy={copy}
                        key={SLindex}
                        heightTopLevel={heightTopLevel}
                        setHeightTopLevel={setHeightTopLevel}
                        refContentTopLevel={refContentTopLevel}
                        TLindex={index}
                        SLindex={SLindex}
                        length={array.length}
                      />
            })}
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default DocumentsAccordion;
