import React from "react";
import PageTransition from "../helpers/page-transition";
import Header from "./header"
import Footer from "./footer"


const LayoutWrapper = ({children, location}) => {
  return (
    <div>
      <Header />

      <PageTransition location={location}>
          {children}
      </PageTransition>

      <Footer />
    </div>
  )
}

export default LayoutWrapper;