import React from "react"
import { graphql } from "gatsby"
import PageNotFound from "../modules/page-not-found"
import ThreeUpCards from "../modules/three-up-cards"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const page = data.wpPage.homePage;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <PageNotFound></PageNotFound>
      <ThreeUpCards
        copy1={page.threeUpCards.threeUpCardsBodyCopy1}
        header1={page.threeUpCards.threeUpCardsHeader1}
        cta1={page.threeUpCards.threeUpCardsCtaButton1}
        copy2={page.threeUpCards.threeUpCardsBodyCopy2}
        header2={page.threeUpCards.threeUpCardsHeader2}
        cta2={page.threeUpCards.threeUpCardsCtaButton2}
        copy3={page.threeUpCards.threeUpCardsBodyCopy3}
        header3={page.threeUpCards.threeUpCardsHeader3}
        cta3={page.threeUpCards.threeUpCardsCtaButton3}
      />
    </Layout>

  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(uri: {eq: "/"}) {
      homePage {
        threeUpCards {
          threeUpCardsHeader1
          threeUpCardsHeader2
          threeUpCardsHeader3
          threeUpCardsBodyCopy1
          threeUpCardsBodyCopy2
          threeUpCardsBodyCopy3
          threeUpCardsCtaButton1 {
            target
            title
            url
        }
          threeUpCardsCtaButton2 {
            target
            title
            url
        }
          threeUpCardsCtaButton3 {
            target
            title
            url
        }
      }
    }
  }
}
`
